// import { onUploadFileClick, prepareFile } from '../../../utils/selectPhoto'
import { getOrientation, onUploadFileClick, prepareFile } from '@/utils/selectPhoto'
import { setStoreWrapper } from '../../../utils/StoreManager'
import { createDialogMediaUpload } from '../../../components/Panels/createPanelUtil'
import { getTopPanelType } from '../../app/actions'
import { UPLOAD_DIALOG_MEDIA } from '../../../components/Panels/types'
import { checkDialogMsgPermission } from '../../../utils/subscriptionUtil'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { isPaidDialogMessage } from '../../../utils/chat/dialogUtil'

export function dialogAddMediaClick(uid, dialogId) {

  //аналитика покупки
  sendAmplitudeEvent('dialog_media', {
    action: 'attach_click'
  })

  if (!checkDialogMsgPermission(uid)) {
    //аналитика покупки
    sendAmplitudeEvent('dialog_media', {
      action: 'dialog_access_deny'
    })
    return
  }

  //выбор файла
  onUploadFileClick(({ file }) => {
    // upload.selectedFileUrl
    console.log('fileSelected ' + file.type)
    if (file.type.indexOf('image') === 0) {
      const onGetPhoto = fileInfo =>
        getOrientation(file, orientation => {
          console.log('orientation ' + orientation)
          setFile({ ...fileInfo, uid, dialogId })
        })
      prepareFile(file, onGetPhoto)
    } else {
      const video = true
      prepareFile(file, fileInfo => setFile({ ...fileInfo, uid, dialogId, video }))
    }
  })
}

function setFile(props) {
  setStoreWrapper('mediaTypeVideo', props.video)
  setStoreWrapper('upload.selectedFile', props.file)
  setStoreWrapper('upload.selectedFilename', props.filename)
  setStoreWrapper('upload.selectedFileUrl', props.url)
  // setStoreWrapper('upload.selectedPhotoOrientation', props.orientation)

  console.log('openDialogMediaPanel')
  if (getTopPanelType() !== UPLOAD_DIALOG_MEDIA) {
    createDialogMediaUpload(props.uid, props.dialogId)
  }
}

export function checkFreeSendMedia(uid) {
  return !isPaidDialogMessage(uid)
  /** Старая проверка
   const user = getUser(uid)

   if(user && !user.model){
   return true
   }

   if (user && user.storage.free_incoming_media){
   return true
   }
   return false
   */
}


import React from 'react'
import controller from '@/controller'
import styled from '@emotion/styled'
import propsOverrideCss from '@/utils/propsOverrideCss'
import isVip from './isVip'
import { isTWA } from './twa'
import { isPostNSFW, isStreamNSFW } from './ageRating/ageRatingUtil'
import { SUPPORT_UID } from '../modules/app/signals/openSupport'
import { getVipPanel, subscriptionPanel } from '../components/Panels/types'
import { getMyUid, isMyProfileSet } from './my'
import { getSequence } from './StoreManager'
import { getPostData } from './postUtils'
import { getAppConfig } from './appConfigUtil'
import { CHAT_DISABLE, CHAT_PROFILE, CHAT_VIP } from './chat/chatUtil'
import { checkEnergy, ENERGY_LIKE } from './energy/energyUtil'

export const CHAT_PERMISSION = 'CHAT_MESSAGE'
export const COMMENT_PERMISSION = 'COMMENT'
export const LIVE_PERMISSION = 'LIVE'
export const PRIVATE_MSG_PERMISSION = 'PRIVATE_MESSAGE'
export const POST_PERMISSION = 'POST'

export const LEVEL_0 = 'LEVEL_0'
export const LEVEL_1 = 'LEVEL_1'
export const LEVEL_2 = 'LEVEL_2'
export const LEVEL_3 = 'LEVEL_3'
export const LEVEL_VIP = 'LEVEL_VIP'

const IconSize = styled.i({
  fontSize: 28,
  lineHeight: '28px',
  width: 28,
  color: '#000000',
  padding: '0 4px',
  ':before': {
    margin: 0,
  },
}, propsOverrideCss)

export const getSubTypeStar = (type, noSubColor = '#000') => {
  return (

    <i className={type ? 'icon-flirty-like-full buttonIcon' : 'icon-flirty-like buttonIcon'}
       style={{ color: getSubTypeColorCustom(type, noSubColor) }}/>
  )
}

export const getSubTypeStarAll = (type, noSubColor = '#000') => {
  if (type === LEVEL_VIP) {
    return (
      <i className="buttonIcon icon-flirty-crown" style={{ color: '#fbbd2c' }}/>
    )
  }
  const iconClass = !type ? 'icon-user-group' : 'icon-flirty-like-full'
  return (
    <i className={'buttonIcon ' + iconClass}
       style={{ color: (!type) ? '#212121' : getSubTypeColorCustom(type, '#000') }}/>
  )
}

export const getSubTypeStarSize = (type, noSubColor = '#000') => {
  return (
    !type
      ?
      <IconSize className="icon-flirty-like" style={{ fill: noSubColor }}/>
      :
      <IconSize className="icon-flirty-like-full" style={{ fill: getSubTypeColor(type) }}/>
  )
}

export const getSubTypeText = (type, intl) => {
  if (!intl || !intl.lvl) return
  switch (type) {
    case LEVEL_0:
      return intl.lvl.replace('{0}', 1)
    case LEVEL_1:
      return intl.lvl.replace('{0}', 2)
    case LEVEL_2:
      return intl.lvl.replace('{0}', 3)
    case LEVEL_3:
      return intl.lvl.replace('{0}', 4)
    case LEVEL_VIP:
      return 'VIP'
  }
  return ''
}

export const getSubTypeColor = type => {
  switch (type) {
    case LEVEL_0:
      return '#ffcc00'
    // return '#000'
    case LEVEL_1:
      return '#ffcc00'
    case LEVEL_2:
      return '#34c759'
    case LEVEL_3:
      return '#5ac8fa'
    case LEVEL_VIP:
      return '#ff9000'
  }
  return '#FFF'
}

export function getSubTypeColorCustom(type, color) {
  switch (type) {
    case LEVEL_0:
      return '#000'
    case LEVEL_1:
      return '#ffcc00'
    case LEVEL_2:
      return '#34c759'
    case LEVEL_3:
      return '#5ac8fa'
    case LEVEL_VIP:
      return '#ff9000'
  }
  return color
}

export const getSubTypeAura = type => {
  if (!type) {
    return null
  }
  return ({
    boxShadow: '0 0 0 3px ' + getSubTypeColor(type)
  })
}

export const isSubTypeLower = (mySubType, subTypeNeeded) => {
  if (!subTypeNeeded) {
    return false
  }
  if (subTypeNeeded === LEVEL_VIP) {
    return !isVip()
  }
  if (!mySubType) {
    return true
  }
  if (mySubType === LEVEL_0) {
    return subTypeNeeded === LEVEL_1 || subTypeNeeded === LEVEL_2 || subTypeNeeded === LEVEL_3
  }
  if (mySubType === LEVEL_1) {
    return subTypeNeeded === LEVEL_2 || subTypeNeeded === LEVEL_3
  }
  if (mySubType === LEVEL_2) {
    return subTypeNeeded === LEVEL_3
  }
  if (mySubType === LEVEL_3) {
    return false
  }
  return false
}

export function isVIPPost(postId) {
  const data = getPostData(postId)
  if (!data) {
    return false
  }
  let subType = data.subscriptionType
  if (!subType) {
    if (isTWA() && isPostNSFW()) {
      return true
    } else {
      return false
    }
  }
  return subType === LEVEL_VIP
}

export const postNeedSubscription = postId => {
  const myUid = controller.getState('auth.uid')
  const subs = controller.getState('auth.subs')
  const data = controller.getState(`posts.postsData.${postId}`)
  if (!data) {
    return false
  }
  if (myUid === data.uid) {
    return false
  }

  let needSub = data.subscriptionType
  if (!needSub) {
    if (isTWA() && isPostNSFW()) {
      needSub = LEVEL_VIP
    } else {
      return false
    }
  }

  if (needSub === LEVEL_VIP) {
    return !isVip() ? 'LEVEL_VIP' : false
  }
  if (!subs[data.uid]) {
    return needSub
  }

  const mySubType = subs[data.uid].subscriptionType
  if (isSubTypeLower(mySubType, needSub)) {
    return needSub
  }

  return false
}

export const subscriptionUtil = (uid, actionType) => {
  const myUid = controller.getState('auth.uid')
  if (myUid === uid) {
    return false
  }
  if (controller.getState('app.isInPrivate') === uid) {
    return false
  }

  const subs = controller.getState('auth.subs')
  const user = controller.getState(`app.users.${uid}`)

  if (actionType === LIVE_PERMISSION && isStreamNSFW(uid)) {
    if (isTWA() || !controller.getState(`app.paidRooms.${uid}`)) {
      if (!isVip()) {
        return LEVEL_VIP
      }
    }
  }

  let permission = null
  if (user && user.subscriptionSettings) {
    permission = user.subscriptionSettings[actionType]
  }

  if (actionType === CHAT_PERMISSION && !permission) {
    const customParams = controller.getState('auth.customParams.roomChatOnlyForSubscribers')
    if (customParams && customParams.value === 1) {
      permission = LEVEL_0
    }
  }

  const mySub = subs[uid] ? subs[uid].subscriptionType : null
  if (isSubTypeLower(mySub, permission)) {
    return permission
  }
  return false
}

export const getNeedSubText = (type, intl) => {
  const lock = <i key="0" className="icon-flirty-lock" style={{ fontSize: 18 }}/>
  if (!intl.lvl) {
    return lock
  }

  switch (type) {
    case LEVEL_0:
      return [lock, intl.lvl.replace('{0}', 1).toLowerCase()]
    case LEVEL_1:
      return [lock, intl.lvl.replace('{0}', 2).toLowerCase()]
    case LEVEL_2:
      return [lock, intl.lvl.replace('{0}', 3).toLowerCase()]
    case LEVEL_3:
      return [lock, intl.lvl.replace('{0}', 4).toLowerCase()]
    case LEVEL_VIP:
      return [lock, intl.vip_sbscr_only]
  }
  return ''
}

export const shopPropsType = (type, duration) => {
  switch (type) {
    case LEVEL_1:
      return duration === 'MONTH' ? 'level-1-month-subscribe-click' : 'level-1-year-subscribe-click'
    case LEVEL_2:
      return duration === 'MONTH' ? 'level-2-month-subscribe-click' : 'level-2-year-subscribe-click'
    case LEVEL_3:
      return duration === 'MONTH' ? 'level-3-month-subscribe-click' : 'level-3-year-subscribe-click'
  }
}

export const checkMySubscription = uid => {
  const myUid = controller.getState('auth.uid')
  const subs = controller.getState('auth.subs')
  if (!myUid || myUid === uid) {
    return false
  }

  if (subs[uid]) {
    return subs[uid].subscriptionType
  }

  return false
}

function validatePermission(permission) {
  if (!permission) {
    return permission
  }
  if (permission !== LEVEL_VIP) {
    return null
  }
  return permission
}

export const needSubscription = (uid, actionType, realPermission) => {
  const user = controller.getState(`app.users.${uid}`)
  let permission = null
  if (user && user.subscriptionSettings) {
    permission = user.subscriptionSettings[actionType]
  }
  if (realPermission) {
    return permission
  }

  const myUid = controller.getState('auth.uid')
  if (myUid === uid) {
    return false
  }
  if (controller.getState('app.isInPrivate') === uid) {
    return false
  }

  const subs = controller.getState('auth.subs')

  if (actionType === LIVE_PERMISSION && isStreamNSFW(uid)) {
    if (isTWA() || !controller.getState(`app.paidRooms.${uid}`)) {
      if (!isVip()) {
        return LEVEL_VIP
      }
    }
  }

  if (actionType === CHAT_PERMISSION) {
    //настройки конфига перекрывают настройки юзера
    const appConfig = getAppConfig()
    if (appConfig.chat_mode === CHAT_DISABLE) {
      return CHAT_DISABLE
    }

    //настройки конфига перекрывают настройки юзера
    if (appConfig.chat_mode === CHAT_VIP) {
      if (!isVip()) {
        return LEVEL_VIP
      }
    }

    if (appConfig.chat_mode === CHAT_PROFILE && !isMyProfileSet()) {
      return CHAT_PROFILE
    }

    return false
  }
  permission = validatePermission(permission)

  const mySub = subs[uid] ? subs[uid].subscriptionType : null
  if (isSubTypeLower(mySub, permission)) {
    return permission
  }
  return false
}

export const checkDialogMsgPermission = (uid, noPanel = false) => {
  const permission = needSubscription(uid, PRIVATE_MSG_PERMISSION)

  if (permission && getMyUid() !== SUPPORT_UID) {
    if (permission === LEVEL_VIP) {
      if (!noPanel) {
        getSequence('app.createPanel')(getVipPanel)
      }
      return false
    }
    if (!noPanel) {
      getSequence('app.createPanel')(subscriptionPanel(uid, permission))
    }
    return false
  }

  return true
}

export function isSubscribeAvailable(openPanel) {

  // if (imVIP()){
  //   return true
  // }
  // if (isBuffActive(getMyUid(), NO_LIKE_AVAILABLE)){
  //   if (openPanel){
  //     createBuffPanel(NO_LIKE_AVAILABLE, getMyUid())
  //   }
  //   return false
  // }
  // setTimeout(()=>{
  //   addBuffNew(getMyUid(), NO_LIKE_AVAILABLE, getMyUid())
  // }, 1000)

  return checkEnergy(ENERGY_LIKE)
}

export const getPermissionText = (intl, setting) => {
  setting = validatePermission(setting)
  switch (setting) {
    case LEVEL_VIP:
      return intl.filter_vip
    case LEVEL_0:
      return intl.lvl.replace('{0}', 1)
    case LEVEL_1:
      return intl.lvl.replace('{0}', 2)
    case LEVEL_2:
      return intl.lvl.replace('{0}', 3)
    case LEVEL_3:
      return intl.lvl.replace('{0}', 4)
  }
  //return intl.all_option
  return intl.sbscr_all_users
}


import { getSessionId } from '../cookies'
import { getCoreUrl } from './apiURLUtil'

export async function sendForNeuralCheck(url, callback) {
  try {
    const response = await fetch(getCoreUrl('/api/v1/photos/check?url=' + url), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'ft-sid': getSessionId()
      },
    })
    if (!response.ok) {
      if (callback) {
        callback('error', {})
      }
      return
    }
    const result = await response.json()
    if (callback) {
      callback('success', result)
    }
  } catch (e) {
    console.error('error neuro ' + e)
    if (callback) {
      callback('error', {})
    }
  }
}

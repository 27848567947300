import signals from './signals'

export default {
  state: {
    typeModerFilter: 'potentiallyInteresting',
    data: [],
    page: 0,
    isLoading: false,
    allLoaded: false,
    countCheckedPost: 0,
  },
  sequences: signals
}

import controller from '@/controller'
import { callSequence, getState } from './StoreManager'

import {
  BAD_BUFF_BLOCKED,
  CHICKEN,
  CLEAR_ALL_NEGATIVE_BUFF,
  GIFT_DISCOUNT,
  GOOD_FOR_12,
  QUIZ,
  QUIZ_COOL_DOWN,
  RABBIT,
  SHIELD
} from '../modules/buffs'
import { getMyUid } from './my'
import { isRestrictedMode, isSpecialUid } from './api/PWAUtil'
import { canBuyCheck } from './checkBalanceUtil'

import { imAdmin, imSuperAdmin } from './adminUtil'
import { sendAmplitudeEvent } from './GAEvent'
import { isModeration } from './moderationUtil'
import { closeAllPanels, createBuffViewPanel } from '../components/Panels/createPanelUtil'
import { GIFTS_DISCOUNT_CARD } from './cardGame/cardGameUtil'
import { getSettingsInt } from './settingsUtil'
import { showSnackbarNew } from '../modules/app/factories'
import { getLocaleValue } from './localeUil'
import { getDurationStr } from './stringUtils'

export const isBroadcastBlocked = () => {
  const myUid = controller.getState('auth.uid')
  const buffs = controller.getState(`app.users.${myUid}.multi_buffs`) || {}

  for (const buffId in buffs) {
    const cardInfo = controller.getState(`buffs.cards.${buffId}`) || {}
    if (!cardInfo.blocksBroadcasting) {
      continue
    }
    return buffId
  }

  return false
}

export const getBuffTimeleft = (buffId, uid, fromUid) => {
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  if (!buffs[buffId]) {
    return null
  }

  const allBuffs = buffs[buffId] || []
  const buffData = allBuffs.find(
    b => b.from === fromUid || !fromUid
  )
  if (!buffData) {
    return null
  }
  const startTime = buffData ? buffData.start * 1000 : 0
  const start = new Date(startTime)

  const cards = controller.getState('buffs.cards')
  const cardInfo = cards[buffId]

  return cardInfo && cardInfo.duration && (cardInfo.duration - (Date.now() - start))
}

export const getBuffUTCEndTime = (buffId, uid, fromUid) => {
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  if (!buffs[buffId]) {
    return null
  }

  const allBuffs = buffs[buffId] || []
  const buffData = allBuffs.find(
    b => b.from === fromUid || !fromUid
  )
  if (!buffData) {
    return null
  }
  const startTime = buffData ? buffData.start * 1000 : 0
  const start = new Date(startTime)

  const cards = controller.getState('buffs.cards')
  const cardInfo = cards[buffId]

  return cardInfo && cardInfo.duration && new Date(cardInfo.duration + startTime)
}

export const filterBuffs = (multi_buffs, userId) => {
  const superAdmin = controller.getState('auth.superAdmin')
  const admin = controller.getState('auth.admin')
  const cardData = controller.getState('buffs.cards')
  const myUid = controller.getState('auth.uid')

  if (!multi_buffs) {
    return {}
  }

  return Object.keys(multi_buffs)
    .filter(buffId => {
      const cardInfo = cardData[buffId]
      if (!cardInfo) {
        return false
      }
      if (superAdmin || admin) {
        return true
      }
      if (buffId === QUIZ) {
        return false
      }
      if (buffId === QUIZ_COOL_DOWN) {
        return false
      }
      if (cardInfo.visible === 'owner' && userId !== myUid) {
        return false
      }
      if (cardInfo.visible === 'admin') {
        return false
      }
      if (getBuffTimeleft(buffId, userId, multi_buffs[buffId].fromUid) < 0) {
        return false
      }
      return true
    })
    .map(buffId => multi_buffs[buffId])
    .flat(1)
}

export function addBuffNew(uid, buffId, fromUid) {
  callSequence('buffs.addBuff', { buff: { cardType: buffId, toUid: uid, startTime: Date.now(), fromUid: fromUid } })
}

export const isBuffActive = (uid, buffId, fromUid = null) => {
  //если это ограниченный режим, и ограниченный  UID то бафф одобрения 12 всегда показыввается
  // if (isRestrictedMode() && isSpecialUid(uid) && buffId === GOOD_FOR_12) {
  //   return true
  // }

  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}
  /*
  multi_buffs: {
    1013: [
      {
        from: "6f29b9e66605467680430ed47f926f3c",
        id: 1013,
        start: 1627039818
      }
    ]
  }
  */

  if (!buffs[buffId]) {
    return false
  }

  const cardInfo = controller.getState(`buffs.cards.${buffId}`) || {}
  buffs[buffId] = buffs[buffId].filter(
    b => {
      const start = b.start * 1000
      const timePassed = Date.now() - start

      return cardInfo.duration && timePassed < cardInfo.duration
    }
  )
  controller.flush()

  const multiBuff = buffs[buffId] || []
  for (let i = 0; i < multiBuff.length; i++) {
    const buff = multiBuff[i]
    if (fromUid && buff.from !== fromUid) {
      continue
    }
    return true
  }

  return false
}

export function getBuffCost(buffId) {
  const buff = getBuff(buffId)
  return buff && buff.cost
}

export function getBuffDuration(buffId) {
  const buff = getBuff(buffId)
  return buff && buff.duration
}

export function getBuff(buffId) {
  return (getState('buffs.cards') || {})[buffId]
}

export function sendBuff(uid, buffId, autoBuff) {
  const cost = getBuffCost(buffId)
  if (!canBuyCheck(cost)) {
    return
  }
  sendAmplitudeEvent('buff', {
    action: 'send',
    buffId,
    cost
  })
  callSequence('buffs.sendBuff', { buffId, method: 'POST', uid })

  if (!isModeration && !autoBuff) {
    closeAllPanels()
    // closeCurrentPanel()
  }
}

export function isBadBuff(buffId) {
  const buff = getBuff(buffId)
  return buff && buff.bad
}

export function isClearBuff(buffId) {
  return buffId == CLEAR_ALL_NEGATIVE_BUFF || buffId == SHIELD
}

export function isSpecialBuff(buffId) {
  return isBadBuff(buffId) || isClearBuff(buffId) || buffId == BAD_BUFF_BLOCKED
}

export function isSpecialPanelBuff(buffId) {
  return buffId < 1000
}

export function isVisibleBuff(buffId, toUid) {
  const cardInfo = getBuffConfig(buffId)
  if (imAdmin() || imSuperAdmin()) {
    return true
  }
  if (cardInfo.invisible) {
    return false
  }
  if (cardInfo.visible === 'admin') {
    return false
  }
  if (cardInfo.visible === 'owner' && toUid !== getMyUid()) {
    return false
  }

  return true
}

export function getBuffConfig(buffId) {
  const allCards = getState('buffs.cards') || {}
  return allCards[buffId]
}

export function onRabbitClick(uid) {
  universalBuffClick(uid, RABBIT)
}

export function onChickenClick(uid) {
  universalBuffClick(uid, CHICKEN)
}

function universalBuffClick(uid, buffId) {
  //достанем первый попавшийся баф курицы на этом юзере и откроем информацию о нем
  const buffs = controller.getState(`app.users.${uid}.multi_buffs`) || {}

  if (!buffs[buffId]) {
    return false
  }

  const cardInfo = controller.getState(`buffs.cards.${buffId}`) || {}
  buffs[buffId] = buffs[buffId].filter(
    b => {
      const start = b.start * 1000
      const timePassed = Date.now() - start

      return cardInfo.duration && timePassed < cardInfo.duration
    }
  )
  controller.flush()

  const multiBuff = buffs[buffId] || []
  for (let i = 0; i < multiBuff.length; i++) {
    const buff = multiBuff[i]
    return createBuffViewPanel(buffId, uid, buff.from)
  }

  return false
}

export function addBuffSnackbar(buffId, awardId) {
  let text
  if (buffId === GIFT_DISCOUNT) {
    // + '_ny'
    text = getLocaleValue('card_dscr_' + GIFTS_DISCOUNT_CARD, { '%dicsount%': getSettingsInt('gift_discount_value') + '%' })
    text += '\n' + getLocaleValue('duration_buff', { '{0}': getDurationStr(getBuffDuration(buffId)) })
  } else {
    text = getLocaleValue('card_dscr_' + awardId)
  }
  showSnackbarNew(text,
    5000,
    'buff',
    {
      buffId,
      awardId
    }
  )
}

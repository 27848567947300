import { state } from 'cerebral'
import config from '@/config'
import { publicTagsAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { imAdmin } from '../../../utils/adminUtil'
import { getState, setStoreWrapper } from '../../../utils/StoreManager'

const { vbApiUrl } = config

export const loadTags = async (categoryIds, cb) => {
  if (categoryIds.length === 0) return
  let params = `?ageRating=${publicTagsAgeRating}`
  params += '&categories=' + categoryIds.join()
  params += '&limit=9999'
  const subj = getState('app.subject')
  if (subj) {
    params += `&subject=${subj}`
  }
  try {
    const response = await fetch(
      `${vbApiUrl}/vb2/topTags/${getState('intl.lang')}` + params
    )
    if (!response.ok) {
      if (cb) {
        cb()
      }
      return
    }

    const result = await response.json()
    const catTags = getState('upload.categoriesTags')

    saveLikeTags(result)
    setStoreWrapper('upload.categoriesTags', { ...catTags, ...result })
    if (cb) {
      cb()
    }
  } catch (e) {
    console.log('topTags error')
    console.log(e)
    if (cb) {
      cb()
    }
  }
}

function saveLikeTags(tagsInCats) {
  const tagsData = getState('intl.tagsData')
  Object.keys(tagsInCats).map(categoryID => {
    const tags = tagsInCats[categoryID].topTags
    tags.forEach(tagData => {
      if (!tagsData[tagData.tagId]) {
        tagsData[tagData.tagId] = { ...tagData, categories: [categoryID] }
      }
    })
  })
  setStoreWrapper('intl.tagsData', tagsData)
}

export async function goLoadCategoryTags(postKind, cb) {
  const categoryIds = getState(`intl.postKinds.${postKind}.categoryIds`)
  if (!categoryIds || categoryIds.length === 0) {
    return
  }

  const postCategories = getState('intl.postCategories') || {}
  const catTags = getState('upload.categoriesTags') || {}

  const categoryIdsAllRatings = []
  const categoryIdsHighRatings = []
  categoryIds.forEach(categoryId => {
    const cat = postCategories[categoryId]
    if (!cat) return
    if (catTags[categoryId]) return
    if (cat.hideLowAgeTags)
      categoryIdsHighRatings.push(categoryId)
    else
      categoryIdsAllRatings.push(categoryId)
  })

  categoryIdsAllRatings.concat(categoryIdsHighRatings)

  if (imAdmin()) {
    if (!getState('upload.categoriesTags.admins')) {
      categoryIdsAllRatings.push('admins')
    }
    if (!getState('upload.categoriesTags.seo')) {
      categoryIdsAllRatings.push('seo')
    }
  }
  await loadTags(categoryIdsAllRatings, cb)
}

export default [
  async ({ get, store }) => {
    await goLoadCategoryTags(get(state`upload.postKind`))
  }
]

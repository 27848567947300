import { state } from 'cerebral'
import { getSessionId } from '@/utils'
import config from '@/config'

const { postUploadUrl } = config

const sendCreateAvatar = async ({ get, store, mainFileId }) => {
  const response = await fetch(`${postUploadUrl}/api/v1/media-file/create-avatar`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      sid: getSessionId(),
      file_id: get(state`upload.mainFileId`) || mainFileId,
      left_percent: (100 * get(state`upload.avaX1`) / get(state`upload.avaW`)) || 0,
      right_percent: (100 * get(state`upload.avaX2`) / get(state`upload.avaW`)) || 0,
      top_percent: (100 * get(state`upload.avaY1`) / get(state`upload.avaH`)) || 0,
      bottom_percent: (100 * get(state`upload.avaY2`) / get(state`upload.avaH`)) || 0
    })
  })

  if (response.status !== 200) {
    return { avatarUrl: null }
  }
    const res = await response.json()

  store.set('upload.avaW', 0)
  store.set('upload.avaH', 0)
  store.set('upload.avaX1', 0)
  store.set('upload.avaX2', 0)
  store.set('upload.avaY1', 0)
  store.set('upload.avaY2', 0)

  return { avatarUrl: res.result.url }
}

export default sendCreateAvatar

import { generateFingerprint, requestToken } from '../actions'
import { set } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { callSequence } from '../../../utils/StoreManager'
import { setMyGender } from '../../../utils/api/setGenderUtil'

export default [
  set(state`auth.isAuthComplete`, false),
  requestToken, {
    success: [
      ({ props }) => {
        setMyGender(props.gender, () => {
          callSequence('auth.getAuthInfo')
        })
      }
    ],
    error: [
      generateFingerprint,
      set(state`auth.token`, props`fingerprint`),
      requestToken, {
        success: [
          ({ props }) => {
            setMyGender(props.gender, () => {
              callSequence('auth.getAuthInfo')
            })
          }
        ],
        error: []
      }
    ]
  }
]

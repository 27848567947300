import signals from './signals'

export default {
  state: {
    content: {
      reconnect: 'Trying to reconnect.',
      free_girls: 'Girls LIVE. No payment. No registration.',
      chat_go: 'Go to video chat'
    },
    country: '',
    countriesList: null,
    crossPromo: null,
    marketingMediums: null,
    tagsData: {},
    bannedCountries: {},//пример: {ru: true, fr: true}
    lang: null,
    direction: 'ltr',
    settings: null,
    autoTranslation: true,
    availableLocales: {
      en: { name: 'English' },
      ru: { name: 'Русский' },
    },
    trustedDomains: [
      'youtube.com',
      'www.youtube.com',
      'vimeo.com',
      'twitch.tv',
      'reddit.com',
      'vk.com',
      'instagram.com',
      'facebook.com',
      'twitter.com',
      'play.famobi.com',
      'famobi.com',
      '204305.selcdn.ru',
      'cdn.witchhut.com',
      'gamedistribution.com',
      'ziango.com',
      'www.gameflare.com',
      'wanted5games.com',
      'www.crazygames.com',
      'html5.inlogic.sk',
      'donatepay.ru',
      'donationalerts.ru',
      'streamlabs.com',
      'destream.net',
      'donate.qiwi.com',
      'donate.stream',
      'fundly.com',
      'paypal.com',
      'firstgiving.com',
      'doublethedonation.com',
      'snowballfundraising.com',
      'charityengine.net',
      'salsalabs.com'
    ],
    spam: [],
    postKinds: {
      postProfile: {
        'kindId': 'postProfile',
        'categoryIds': [
          'people',
          'profile_languages',
          'videochat_questionnaire_purpose',
          'profile_housing',
          'profile_kids',
          'profile_education',
          'profile_religion',
          'profile_smoking',
          'profile_height',
          'videochat_questionnaire_interests',
          'videochat_questionnaire_job'
        ],
        'autoTags': [
          1067952
        ],
        'subjects': [],
        'priority': 0
      },
      videochat_photo: {
        'kindId': 'videochat_photo',
        'categoryIds': [
          'photo_tags'
        ],
        'autoTags': [
          1085661
        ],
        'subjects': [
          'videochat',
          'adult_videochat',
          'mobile_videochat',
          'men_videochat',
          'mobile_videochat_ios'
        ],
        'priority': 900
      },
      post: {
        kindId: 'post',
        categoryIds: []
      }
    },
    usersTop: [],
  },
  sequences: signals
}

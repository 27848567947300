import { getState, setStoreWrapper } from '../StoreManager'
import { getTopPanelType, isPanelType } from '../../modules/app/actions'
import { PROFILE_CREATION_PANEL } from '../../components/Panels/types'

export function setAvatarChanged() {
  setStoreWrapper('avatarChanged', true)
}

export function isAvatarChanged() {
  return getState('avatarChanged')
}

export function setAvatarUpdated() {
  setStoreWrapper('avatarChanged', false)
}

export function clearPostCreatedData() {
  console.log('PT ' + getTopPanelType())
  if (isPanelType(PROFILE_CREATION_PANEL)) {
    return
  }
  setStoreWrapper('upload.description', null)
  setStoreWrapper('upload.title', null)
  setStoreWrapper('upload.subscriptionType', null)
  setStoreWrapper('upload.selectedTags', [])
  setStoreWrapper('upload.selectedFile', null)
  setStoreWrapper('upload.selectedFilename', null)
  setStoreWrapper('upload.selectedFileUrl', null)
  setStoreWrapper('upload.selectedPhotoOrientation', null)
  setStoreWrapper('upload.mainFileId', null)
  setStoreWrapper('posts.selectedPostId', null)
  setStoreWrapper('savingProfile', false)
  setStoreWrapper('upload.extraFiles', [])
  setStoreWrapper('upload.selectedTags', [])
}

import { getState } from './StoreManager'
import getStreamMetadata from './getStreamMetadata'
import { isTWA } from './twa'
import { getLocaleValue } from './localeUil'
import { isStreaming } from '../modules/socket/actions'
import { isCallDisableInConfig, isDatingApp, isKickMode } from './appConfigUtil'
import { isSkinForCall } from './api/PWAUtil'
import { getSettings } from './settingsUtil'

export function setDefaultTheme() {
  //если выбрана не одна тема, а несколько, то скинем в дефолтную тему
  const privateShowThemes = getPrivateThemes()
  const bannedThemes = getState('room.bannedPrivates')
  let availableThemesCount = 0

  //пройдемся по доступным темам и убедимся, что разрешена одна
  for (const themeId in privateShowThemes) {
    if (!bannedThemes[themeId]) {
      availableThemesCount++
    }
  }

  //если больше 1 то нужно оставить одну
  if (availableThemesCount > 1) {

  }
}

// export function setMyTheme() {
//   for (const themeId in privateShowThemes) {
//     if (!bannedThemes[themeId]){
//       availableThemesCount++
//     }
//   }
//   video_call_200
//   if (imModel()){
//     romantic_date_14
//   } else {
//     romantic_date
//   }
// }

export function getPrivateThemes() {
  const privateShowThemes = (getSettings('direct_calls') || {})
  //фри звонок пока отключен
  delete privateShowThemes[FREE_CALL]
  return privateShowThemes
}

export const FREE_CALL = 'free_call'

//получает список тем приватов юзера
export function getUserPrivateThemes(uid) {
  const metadata = getStreamMetadata(uid)
  if (!metadata) {
    return isKickMode() ? ['video_call_900'] : ['video_call_900', 'romantic_date_14', 'video_call_2400']
  }
  let privates = metadata && metadata.theme && metadata.theme.privateShowThemes
  if (privates) {
    if (!(privates instanceof Array)) {
      privates = isKickMode() ? ['video_call_900'] : ['video_call_900', 'romantic_date_14', 'video_call_2400']
    }
  }

  let manualSorted = []
  if (!privates) {
    return privates
  }

  if (privates.includes('video_call_900')) {
    manualSorted.push('video_call_900')
  }
  if (privates.includes('romantic_date_14')) {
    manualSorted.push('romantic_date_14')
  }
  if (!isKickMode()) {
    //theme_romantic_date_14
    if (privates.includes('video_call_2400')) {
      manualSorted.push('video_call_2400')
    }
  }

  // if (privates.includes('video_call_200')){
  //   manualSorted.push('video_call_200')
  // }

  // if (!isStreaming(uid, true)){
  return manualSorted
  // }

  // return manualSorted.reverse()

  /** Отключим авто выбор темы
   *
   //если у меня есть приваты и это не TWA то оставим только одну тему
   if (privates && !isTWA()){
   //если я модель, то одно из свиданий, если оно активно
   if (isModel(uid)){
   if (privates.includes('romantic_date_14')){
   privates = ['romantic_date_14']
   return privates
   }
   if (privates.includes('romantic_date')){
   privates = ['romantic_date']
   return privates
   }
   } else {
   if (privates.includes('video_call_200')){
   privates = ['video_call_200']
   return privates
   }
   }
   }
   */
  return privates
}

export function getDirectCallButtonText(uid, withCam) {
  const privates = getUserPrivateThemes(uid)//metadata && metadata.theme && metadata.theme.privateShowThemes

  return getLocaleValue(isKickMode() ? 'call_alt' : 'call')
  /** отключим цену на кнопке звонка
   if (privates && privates.length > 1) {
   return getLocaleValue('call')
   }
   const rawPosters = getState('posters.raw')
   if (privates && privates.length === 1) {
   if (isTWA() || !rawPosters[uid]){
   return getLocaleValue('call')
   } else {
   return (isMobileSkin() ? '' : getLocaleValue('call') + ' ') + COIN_ICON + getPrivateCost(withCam,privates[0] || 'romantic_date')
   }
   }
   */
}

export function isPrivateAvailable(uid) {
  if (isDatingApp()) {
    return false
  }
  const privates = getUserPrivateThemes(uid)
  //уберем звонок в офлайн для TWA
  if (isTWA() && !isStreaming(uid, true)) {
    return false
  }
  if (privates && privates.length > 0) {
    //настройки конфига перекрывают настройки юзера
    // const appConfig = getAppConfig()
    return !isCallDisableInConfig()
  }

  return false
}

export function isVIPOnlyCall(themeId) {
  return themeId === 'video_call_200' || themeId === 'video_call_900'
}

export function getCallTitle(themeId) {
  if (themeId === FREE_CALL) {
    return getLocaleValue('free_call')
  }
  if (isKickMode()) {
    if (themeId === 'video_call_900') {
      return getLocaleValue('theme_romantic_date_14_title_alt') + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')
    }

    if (themeId === 'romantic_date_14') {
      return getLocaleValue('theme_romantic_date_14_title_alt') + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')
    }
  }
  if (isSkinForCall()) {
    //SD
    if (themeId === 'video_call_900') {
      return getLocaleValue('theme_romantic_date_title') + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')
    }

    if (themeId === 'romantic_date_14') {
      return getLocaleValue('theme_romantic_date_title') + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')
    }

    if (themeId === 'video_call_2400') {
      return getLocaleValue('theme_video_call_2400_title_ios') + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')
    }
  }
  return (getLocaleValue(`theme_${themeId}_title`)) + (isVIPOnlyCall(themeId) ? ' (' + getLocaleValue('vip_call_discount', { '%num%': '50%' }) + ')' : '')

  //HD theme_video_call_2400_title_ios
  //LD theme_video_call_200_title_ios
}

import { send } from '../../socket/actions'
import { set, wait, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import panelsOnPop from './panelsOnPop'
import getPrivateCost from '@/utils/getPrivateCost'
import { routeToFactory, showSnackbar } from '../factories'
import { checkForVideoPrivateAvailability } from '../actions/checkForVideoPrivateAvailability'
import sendGoInRoom from '../../room/signals/sendGoInRoom'
import {
  noCoinsForVideoCall,
  noVideoDeviceForCall,
  offlineCallEvent,
  tryToStartVideoCall
} from '../../../utils/amplitudeLiveEvents'
import { createCallPanel, createGetVipPanel } from '../../../components/Panels/createPanelUtil'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import roomTransited from './roomTransited'
import { postersRoute } from '../../../utils/routerHelpers'
import { imVIP } from '../../../utils/my'
import { sendAmplitudeEvent } from '../../../utils/GAEvent'
import { canBuyCheck } from '../../../utils/checkBalanceUtil'
import { isVIPOnlyCall } from '../../../utils/directCallThemeUtil'

const checkModelStatus = ({ get, props, path }) => {
  const rawPosters = get(state`posters.raw`)
  if (rawPosters[props.roomId]) {
    return path.startPrivate()
  }
  return path.sendCallToPrivate()
}

export const startPrivate = [
  () => ({ hideAll: true }),
  panelsOnPop,
  when(state`app.roomId`, props`toUid`, (roomId, toUid) => roomId === toUid), {
    true: [],
    false: [
      routeToFactory(postersRoute),
      wait(100),
      ({ props }) => ({ roomId: props.toUid }),
      roomTransited,
    ]
  },
  set(state`room.allowedPaidStreamId`, props`roomId`),
  sendGoInRoom,
  ({ props, get }) => ({
    params: {
      model: props.roomId || get(state`room.callingTo`),
      audioOnly: props.audioOnly,
      withCam: props.withVideo === undefined ? get(state`room.callingWithCam`) : props.withVideo,
      themeId: props.themeId || 'romantic_date',
    },
    method: 'directCallStart'
  }),
  send,
  ({ store, props }) => {
    if (props.params.withCam) {
      store.set('room.streamInRoom', props.params.model)
    }
  },
  set(state`room.callingTo`, null),
]

const sendCallToPrivate = [
  ({ props }) => {
    offlineCallEvent(props.roomId)
  },
  ({ props }) => ({
    method: 'callToPrivate',
    params: {
      toUid: props.roomId,
      themeId: props.themeId,
      withCam: props.withVideo,
    }
  }),
  send,
  set(state`room.callingTo`, props`roomId`),
  set(state`room.callingThemeId`, props`themeId`),
  set(state`room.callingWithCam`, props`withVideo`),
  wait(50),
  ({ props }) => {
    createCallPanel(props.roomId, false, props.themeId)
  }
]

export default [
  when(state`auth.coins`, props`withVideo`, props`themeId`, state`auth.pricesRate`, props`audioOnly`,
    (coins, withVideo, themeId, pricesRate, audioOnly) => {
      if (isVIPOnlyCall(themeId) && !imVIP()) {
        sendAmplitudeEvent('video_call', {
          progress: 'need_vip_for_theme'
        })
        createGetVipPanel()
        return false
      }
      if (!canBuyCheck(Math.floor(pricesRate * getPrivateCost(withVideo, themeId, audioOnly)), 'private-show-start')) {
        noCoinsForVideoCall()
        return false
      }

      if (audioOnly) {
        sendAmplitudeEvent('audio_only_call', {
          theme_id: themeId
        })
      }
      setStoreWrapper('audioOnly', audioOnly)
      // переключим на видео
      if (!audioOnly && withVideo) {
        setStoreWrapper('room.publisherCamEnabled', true)
      }
      return true
    }
  ), {
    true: [
      checkForVideoPrivateAvailability, {
        noVideoDevicesOrBisy: [
          noVideoDeviceForCall,
          showSnackbar(state`intl.content.camera_not_found`, 5000, 'error')
        ],
        notAvailable: [
          noVideoDeviceForCall,
          () => {
            callSequence('app.panelsOnPop')
          },
          showSnackbar(state`intl.content.private_show_no_show_available`, 5000, 'error')
        ],
        available: [
          when(state`app.roomId`, props`toUid`, (roomId, toUid) => roomId === toUid), {
            true: [],
            false: [
              routeToFactory(postersRoute),
              wait(100),
              ({ props }) => ({ roomId: props.toUid }),
              roomTransited,
            ]
          },
          async ({ props }) => {
            if (props.withVideo) {
              tryToStartVideoCall()
              // await getUserMedia()
            }
          },
          () => ({ hideAll: true }),
          panelsOnPop,
          checkModelStatus,
          { startPrivate, sendCallToPrivate }
        ]
      },
    ],
    false: [
      // noCoinsForVideoCall,
      // setNoCoinsMessage,
      // openShop
    ]
  }
]

function setNoCoinsMessage({ store, get, props }) {
  return {
    reason: 'private-show-start'
  }
}

import { state } from 'cerebral'
import { changePasswordPanelStepTwo } from '../../../components/Panels/types'
import { showSnackbar } from '../../app/factories/index'
import createPanel from '../../app/signals/createPanel'
import { getSessionId } from '@/utils'
import config from '@/config'

const { httpApiUrl } = config

export default [
  async ({ path, get }) => {
    const { oldPassword } = get(state`auth.changePasswordForm`)

    const fd = new FormData()
    fd.append('sid', getSessionId())
    fd.append('password', oldPassword)
    fd.append('domain', location.host)

    const response = await fetch(httpApiUrl + '/auth/check_pass', {
      method: 'POST',
      body: fd
    })
    if (response.ok) {
      const { result } = await response.json()
      if (result) {
        return path.success()
      }
    }
    return path.error()
  }, {
    success: [
      () => ({ options: changePasswordPanelStepTwo.options }),
      createPanel
    ],
    error: [
      showSnackbar(state`intl.content.wrong_pass`, 3000, 'error'),
    ]
  }
]

import {
  checkIfTranslationIsPaidOrPrivate,
  checkUnreadComments,
  onmessage,
  onPrivateShowStart,
  send,
  updateStreams,
  updateStreamsAnt,
  updOnline
} from '../actions'
import { generateFingerprint, requestToken } from '../../auth/actions'
import { getUser, loadUser, requestUsers } from '@/utils/userLoader'
import { increment, set, unset, when } from 'cerebral/factories'
import { props, state } from 'cerebral'
import { COIN_ICON, Ws } from '@/utils'
import controller from '@/controller'
import { CALL_PANEL, loggedFromAnotherPlacePanel } from '../../../components/Panels/types'
import createPanel from '../../app/signals/createPanel'
import {
  addGiftsMessage,
  banMsg,
  checkChatMessage,
  deleteAllMessageFromUser,
  onNewMessage,
  onPrivateMessage,
  onRoomHistory
} from '../../chat/actions'
import chatSignals from '../../chat/signals'
import { addBuff, delBuff, } from '../../buffs/actions'
import { addPostModerationStatus } from '../../posts/actions'
import uncacheUser from '../../app/actions/uncacheUser'
import { routeToFactory, showSnackbar } from '../../app/factories'
import showSnackbarSignal from '../../app/signals/showSnackbar'
import { checkIfUserModelOrViewer, getTopPanelType, isPanelType, setPaidTranslations } from '../../app/actions'
import { initABCTest, onPurchase, onVipPurchase, sendAmplitudeEvent } from '@/utils/GAEvent'
import sendCreateBroadcast from '../../room/signals/sendCreateBroadcast'
import { unsetPaidTranslation } from '../../posters/factories'
import getAuthInfo from '../../auth/signals/getAuthInfo'
import openShop from '../../app/signals/openShop'
import onStartWatchVideo from '../../posts/actions/onStartWatchVideo'
import showPrivateStartMsg from '../../room/actions/showPrivateStartMsg'
import { addMessageLocally } from '../../chat/actions/addMessageLocally'
import panelsOnPop from '../../app/signals/panelsOnPop'
import { setDefaultFilter } from '../../posts/signals/setFilter'
import onConnect from './onConnect'
import changeLocale from '../../intl/signals/changeLocale'
import { postersRoute } from '@/utils/routerHelpers'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import { hideLoginPanels } from '../../auth/chains'
import getStreamMetadata from '@/utils/getStreamMetadata'
import loadSubsAndFans from '../../auth/actions/loadSubsAndFans'
import { LIVE_PERMISSION, needSubscription } from '@/utils/subscriptionUtil'
import { loadDialogsAction } from '../../chat/actions/loadDialogs'
import prepareAndRouteTo from '../../app/signals/prepareAndRouteTo'
import { getTopUsers } from '../../intl/actions'
import { NO_DAILY_COINS, ROOM_CHAT_BAN } from '../../buffs'
import onPrivateEnd from '../../room/signals/onPrivateEnd'
import getPost from '../../posts/signals/getPost'
import isVip from '../../../utils/isVip'
import { updateEXP } from '../../../components/Levels/EXPManager'
import { addFan, dellFan } from '../../fans/fansUtil'
import { getUserAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { uploadOneSignalId } from '../../../utils/OneSignal'
import { addNewPostsCount } from '../../../utils/newPostsCountUtli'
import { messagesRoute } from '../../../utils/routerHelpers'
import { callSequence, getState, setStoreWrapper } from '../../../utils/StoreManager'
import { closeAllPanels, closeCurrentPanel, createCallPanel } from '../../../components/Panels/createPanelUtil'
import { isBuffActive, isVisibleBuff } from '../../../utils/buffUtils'
import { getMyUid } from '../../../utils/my'
import { addLocalReview, delLocalReview } from '../../../utils/showcase/ratingUtil'
import { sendToWS2 } from '../../../utils'
import { checkIncomeNotification } from '../../auth/actions/notificationUtil'
import { animatedLikeReceived } from '../../../components/Levels/animatedLike'
import { getCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { askQuiz, quizWinner } from '../../../utils/quiz/quizUtil'
import { updateMyEnergy } from '../../../utils/energy/energyUtil'
import { updateLastRead } from '../../../utils/chat/dialogUtil'

export default [
  onmessage, {
    onConnect: [
      onConnect,
      getTopUsers
    ],
    authSuccess: [
      uploadOneSignalId,
      ({ get, path }) => {
        setStoreWrapper('upload.postKind', 'postProfile')

        if (!get(state`auth.lang`)) {
          return path.sendLangToServer()
        }
        if (get(state`auth.lang`) !== get(state`intl.lang`)) {
          return path.changeLang()
        }
        return path.doNothing()
      }, {
        changeLang: [
          ({ get }) => ({ locale: get(state`auth.lang`) }),
          changeLocale
        ],
        sendLangToServer: [
          ({ get }) => ({
            params: { lang: get(state`intl.lang`), country: null },
            method: 'setLangAndCountry'
          }),
          send,
        ],
        doNothing: []
      },
      set(state`auth.isSuccessful`, true),
      // setDefaultFilter,
      hideLoginPanels,
      checkIfTranslationIsPaidOrPrivate, {
        doNothing: [],
        paidNoCoins: set(state`room.allowedPaidStreamId`, null),
        modelInPrivateWithOtherUser: showSnackbar(state`intl.content.private_show_already_in_show`, 5000, 'error')
      },
      // ({ store, get }) => {
      //   const mustSend = get(state`socket.mustSend`)
      //   if (mustSend.length) {
      //     const ws = Ws()
      //     mustSend.forEach(data => {
      //       ws.send(data)
      //       sendToWS2(data)
      //     })
      //     store.set('socket.mustSend', [])
      //   }
      // },
      increment(state`auth.loginCount`),
      checkUnreadComments,
      getPost,
      loadSubsAndFans,
      context => {
        loadDialogsAction(context)
      },
      initABCTest,

      //открытие панели вывода после авторизации, если было нужно
      ({ store, get }) => {
        // eslint-disable-next-line no-undef
        if (get(state`app.panelRoute`) === 'payout' && getTopPanelType() !== 'payout') {
          store.push('app.panels', { type: 'payout' })
        } else if (get(state`app.panelRoute`) === 'dialog' && getTopPanelType() !== 'dialog') {
          controller.getSequence('app.prepareAndRouteTo')({
            url: messagesRoute,
            urlValue: (get(state`app.url`) || '').split('/')[2]
          })
        }
      }
    ],
    startWatchRecord: [
      set(state`posts.buyingPostId`, -1),
      when(props`params.error`), {
        true: [
          ({ get }) => ({
            message: get(state`intl.content.no_coins_for_video`),
            reason: 'buy-paid-videopost'
          }),
          openShop
        ],
        false: [
          onStartWatchVideo
        ]
      },
    ],
    authUnsuccess: [
      ({ props, path }) => {
        if (props.params === 'deleted') {
          closeAllPanels()
          setStoreWrapper('deletedMode', true)
          setStoreWrapper('genderSelectScreen', true)
          return path.doNothing()
        }
        return path.reLogin()
      }, {
        doNothing: [],
        reLogin: [
          requestToken, {
            success: [
              getAuthInfo
            ],
            error: [
              generateFingerprint,
              set(state`auth.token`, props`fingerprint`),
              requestToken, {
                success: [
                  getAuthInfo
                ],
                error: [
                  set(state`auth.isAuthComplete`, true)
                ]
              }
            ]
          }
        ],
      }
    ],
    onPostLike: [],
    onGetTopPosts: [],
    onGetFeedPosts: [],
    onGetNewPosts: [],
    onGetPosts: [],
    onGetUserPosts: [],
    onBan: [
      set(state`app.ban`, props`reason`),
      set(state`app.banId`, props`fine_id`),
    ],
    privateStartError: [
      () => {
        callSequence('app.panelsOnPop')
      },
      showSnackbar(state`intl.content.private_show_no_show_available`, 5000, 'error')
    ],
    loadPosters: [],
    updateOnline: [
      updOnline,
    ],
    clearRoomHistory: chatSignals.clearChat,
    deleteAllMessageFromUser: [deleteAllMessageFromUser],
    dialogMessage: [
      onPrivateMessage,
    ],
    chatMessage: [checkChatMessage, {
      notMy: onNewMessage,
      block: [],
      my: []
    }],
    addBuff: [
      addBuff,
      ({ props, get, path }) => {
        if (props.buff.cardType === NO_DAILY_COINS) {
          return path.doNothing()
        }
        const upMessage = get(state`intl.content.card_${props.buff.cardType}_descr`) ||
          get(state`intl.content.card_${props.buff.cardType}`)

        if (upMessage && props.buff.toUid === get(state`auth.uid`) && isVisibleBuff(props.buff.cardType, props.buff.toUid)) {
          props.upMessage = upMessage
          return path.showMessage()
        }
        return path.doNothing()
      }, {
        doNothing: [],
        showMessage: [
          showSnackbar(props`upMessage`, 5000, props`buff.cardType`),
        ],
      },
    ],
    postModeration: [
      addPostModerationStatus,
    ],
    animatedLike: [
      ({ props }) => {
        animatedLikeReceived(props.params.fromId, getCurrentRoomId(), props.params.durations, true)
      }
    ],
    subscribe: [
      ({ props, get }) => {
        addFan(props.params.uid, props.params)
      }
    ],
    unsubscribe: [
      ({ props, get }) => {
        dellFan(props.params.uid)
      }
    ],
    subscriptionRenewal: [],
    delBuff: [delBuff],
    broadcastsSpecialData: [],
    roomEnterDisable: [
    ],
    roomHistory: [onRoomHistory],
    onAllowNsfw: [],
    vipPurchase: [
      ({ props, store }) => {
        onVipPurchase(props.params)
        store.set('auth.vip_subscription', {
          ...props.params,
          status: 'ACTIVE',
        })
      },
      showSnackbar(state`intl.content.purchase_completed`, 5000, 'success'),
    ],
    // cardGameNewCard:[
    //   ({ props })=>{
    //     addGameCard(props.params.card_id, props.params.uid, props.params.card_type, props.params.strength)
    //   }
    // ],
    // cardGameWinner:[
    //   ({ props })=>{
    //     //TODO сообщение о победителе, можно писать в разных комнатах
    //     clearCardGame()
    //     // addGameCard(props.params.winner, props.params.uid, props.params.card_type, props.params.strength)
    //   }
    // ],
    // cardGameTick:[
    //   ({ props })=>{
    //     syncGame(props.params)
    //   }
    // ],
    // //добавление карточек пачкой
    // cardGameNewCards:[
    //   ({ props })=>{
    //     cardGameNewCards(props.params)
    //   }
    // ],
    loginnedFromAnotherPlace: [
      ({ props, path }) => {
        // getState('authData')
        if (getState('authData') + 1500 > Date.now()) {
          return path.doNothing()
        }
        return path.logOut()
      }, {
        doNothing: [],
        logOut: [
          () => ({ options: loggedFromAnotherPlacePanel.options }),
          createPanel,
          () => Ws().close(),
          () => ({ url: postersRoute }),
          prepareAndRouteTo,
        ],
      }
    ],
    onLoadUsers: [],
    uncacheUser: [uncacheUser],
    transaction: [
      ({ props }) => onPurchase(props.params),
      set(state`auth.coins`, props`params.purchasedCoins`),
      ({ store, get }) => {
        const stuff = get(state`auth.stuff`) || {}
        stuff.lastPurchase = Date.now()
        store.set('auth.stuff', stuff)
      },
      panelsOnPop,
      set(state`shop.openReason`, null)
    ],
    payoutError: [
      showSnackbar(state`intl.content.get_money_error`, 5000),
    ],
    payoutSuccess: [
      //params: {result: 249, usd: 12.479999542236328, newBalance: 0}
      showSnackbar(state`intl.content.get_money_wait`, 5000),
      set(state`auth.usdBalance`, 0),
      ({ get, props }) => sendAmplitudeEvent('payout', {
        sum_usd: Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100,
        payout_id: props.data.result,
        ...get(state`app.payoutData`)
      }),
      unset(state`app.payoutData`)
    ],
    banned: [
      set(state`app.ban`, true),
      set(state`app.banId`, props`params.banId`),
      set(state`room.streamStopped`, true),
    ],
    balanceChanged: [],
    balanceChangedReceivedCoins: [
      ({ store, get, props }) => {
        checkIncomeNotification()
        const coinsToMoney = get(state`intl.settings.coinsToMoney`)
        store.set(state`auth.usdBalance`, Math.trunc(props.params.receivedCoins / coinsToMoney * 100) / 100)
      }
    ],
    experienceChanged: [
      ({ get, props }) => {
        updateEXP(props.params.uid, props.params.experience)
      }
    ],
    balanceChangedPurchasedCoins: [
      set(state`auth.coins`, props`params.purchasedCoins`),
      ({ get, props }) => {
        updateEXP(props.params.uid, props.params.exp)

        //props.params.action  === vip_buy_coins
        if (props.params.action === 'returnLastPayment') {
          const returned = props.params.deltaPurchasedCoins

          const showSnackbar = controller.getSequence('app.showSnackbar')
          showSnackbar({
            text: get(state`intl.content.last_payment_return_action`)
              .replace('%coins%', COIN_ICON + returned),
            type: 'success'
          })
        }
      }
    ],
    createBroadcast: [],
    privateMessage:[],
    endBroadcast: [
      ({ get, props, store }) => {
        const firstViewed = get(state`auth.firstViewed`)
        if (get(state`app.roomId`) !== props.roomId) {
          return
        }

        const isPrivate = get(state`app.isInPrivate`) === props.roomId

        const streamData = get(state`room.streamData`) || {
          bitrate: 0,
          height: 0,
          width: 0,
          protocol: 'none'
        }
        const metadata = getStreamMetadata(props.roomId)

        const roomEnterTime = get(state`room.roomEnterTime`)
        if (roomEnterTime === 0) {
          return
        }

        if (firstViewed && (!isVip() && Math.random() > 0.1 && !isPrivate)) {
          return
        }

        const amData = {
          viewing_category: 'viewing_broadcast',
          content_rating: getAgeRatingByUid(props.roomId),
          model_id: props.roomId,
          subscription_type: '-1',
          broadcast_type: isPrivate ? 'private' : 'public',
          end_reason: 'model',
          starting_duration: 0,
          viewing_duration: 0,
          topic_id: metadata.postThemeId || '-1',
          avg_bitrate: streamData.bitrate / 1000000,
          height: streamData.height,
          width: streamData.width,
          protocol: streamData.protocol || 'HLS',
          firstView: !firstViewed
        }
        if (streamData.videoStartTime > 0) {
          const brWatchTime = Date.now() - streamData.videoStartTime
          if (brWatchTime < 100 || brWatchTime > 7575759 || !props.themeId) {
            return
          }
          const starting_duration = streamData.videoStartTime - roomEnterTime
          if (starting_duration < 10 || starting_duration > 1000000) {
            return
          }

          amData.starting_duration = starting_duration / 1000
          amData.viewing_duration = brWatchTime / 1000
        }

        store.set(state`auth.firstViewed`, true)
        if (amData.viewing_duration) {
          sendAmplitudeEvent('topic_duration_user', amData)
        }
      }
    ],
    privateShowStarts: [],
    directCallStart: [
      when(state`app.roomId`, props`model`, (roomId, model) => roomId === model), {
        true: [
          set(state`app.privateThemeId`, props`themeId`),
          checkIfUserModelOrViewer, {
            model: [
              set(state`app.isInPrivate`, props`viewer`),
              ({ get, props }) => {
                //если я принял звонок в офлайн от юзера, то при завершении звонка мне надо выключить стрим
                if (getState('callAccepted') === props.viewer) {
                  setStoreWrapper('endStreamAfterCall', props.viewer)
                }
                const viewerName = get(state`app.users.${props.viewer}.name`)
                const rawMessage = get(state`intl.content.private_show_starts_for_model_head`)
                const snackbarTitle = rawMessage.replace(/%viewer%/gi, viewerName)
                return { snackbarTitle }
              },
              ({
                 get,
                 props
               }) => ({ snackbarMessages: [props.snackbarTitle, get(state`intl.content.private_show_starts_for_model`)] }),
              showPrivateStartMsg,
              when(props`withCam`), {
                true: [
                  set(state`room.soundMuted`, false),
                  set(state`room.companionUid`, props`viewer`),
                ],
                false: [
                  set(state`room.companionUid`, null),
                  set(state`app.isInPrivateWithVideo`, false),
                ]
              },
              ({ store, get }) => {
                const broadcastingTime = (Date.now() - get(state`room.broadcastStartTime`)) / 1000
                if (broadcastingTime > 1 && broadcastingTime < 50000) {
                  const subscription_type = (get(state`auth.subscriptionSettings.${LIVE_PERMISSION}`) || '-1').toLowerCase()
                  const amData = {
                    subscription_type,
                    broadcast_duration: Math.round(broadcastingTime),
                    broadcast_type: 'public',
                    end_reason: 'user',
                    content_rating: get(state`auth.lastModerRating`) || getAgeRatingByUid(get(state`auth.uid`)),
                    topic_id: 'reality_show'
                  }
                  sendAmplitudeEvent('topic_duration_model', amData)
                }
                store.set('room.broadcastStartTime', Date.now())
              },
            ],
            viewer: [
              ({ store, props }) => {
                store.set('room.roomEnterTime', Date.now())
              },
              set(state`app.isInPrivate`, props`model`),
              ({ get }) => ({ snackbarMessages: [get(state`intl.content.private_show_starts_enjoy_head`), get(state`intl.content.private_show_starts_enjoy`)] }),
              showPrivateStartMsg,
              when(props`withCam`), {
                true: [
                  set(state`room.companionUid`, props`viewer`),
                  set(state`app.isInPrivateWithVideo`, true),
                ],
                false: [
                  set(state`room.companionUid`, null),
                  set(state`app.isInPrivateWithVideo`, false),
                ],
              }
            ],
            other: [
              // showSnackbar(state`intl.content.private_show_starts_exit`, 5000, 'error')
            ]
          }
        ],
        false: [],
      },
      onPrivateShowStart
    ],
    privateCall: [
      ({ props }) => {
        if (isBuffActive(props.params.fromUid, ROOM_CHAT_BAN, getMyUid())) {
          return
        }
        createCallPanel(props.params.fromUid, true, props.params.themeId)
      }

      // ({ props }) => ({
      //   type: props.params.withCam ? 'callToModelWithCam' : 'callToModel'
      // }),
      // showSnackbar(state`intl.content.prvt_call`, 60000, props`type`, props`params.fromUid`)
    ],
    callToPrivateCancel: [
      ({ props }) => {
        if (isPanelType(CALL_PANEL)) {
          closeCurrentPanel()
        }
      }
    ],
    peepShowStarts: [
      when(
        state`app.roomId`,
        props`params.model`,
        props`params.viewer`,
        state`auth.uid`,
        (roomId, model, viewer, uid) => ((roomId === model) && (viewer === uid))
      ), {
        true: [
          set(state`room.peepingApproved`, true),
          ({ get }) => ({
            msgData: {
              isGold: true,
              from: 'fm',
              fromName: 'Videochat',
              postId: Date.now(),
              text: get(state`intl.content.peep_no_cam`)
            }
          }),
          addMessageLocally
        ],
        false: []
      }
    ],
    endPrivateShow: [],
    directCallEnd: onPrivateEnd,
    setPaidBroadcasts2: [],
    setPaidBroadcasts: [
      setPaidTranslations, {
        doNothing: [],
        needCoins: set(state`room.allowedPaidStreamId`, null),
        notNotified: [
          showSnackbar(state`intl.content.paid_broadcast_starts_viewer`, 5000),
        ]
      }
    ],
    setPaidBroadcast: [
      ({ props }) => ({
        params: {
          uid: props.params.model,
          cost: props.params.cost
        }
      }),
      setPaidTranslations, {
        doNothing: [],
        needCoins: set(state`room.allowedPaidStreamId`, null),
        notNotified: [
          showSnackbar(state`intl.content.paid_broadcast_starts_viewer`, 5000),
        ]
      }
    ],
    dellPaidBroadcast: [
      when(state`app.isInPrivate`), {
        true: [
          ({ get, path, props }) => {
            const uid = get(state`auth.uid`)
            const isInPrivateWith = get(state`app.isInPrivate`)
            const model = props.params
            if (model === uid) {
              return path.isModel()
            } else if (model === isInPrivateWith) {
              return path.isViewer()
            } else {
              return path.isOther()
            }
          },
          {
            isModel: [
              when(state`room.streamInRoom`), {
                true: [
                  ({ get }) => {
                    const viewer = get(state`app.isInPrivate`)
                    const viewerName = get(state`app.users.${viewer}.name`)
                    const rawMessage = get(state`intl.content.private_show_viewer_left_show`)
                    const snackbarTitle = rawMessage.replace(/%viewer%/gi, viewerName)
                    return { snackbarTitle }
                  },
                  showSnackbar(props`snackbarTitle`, 5000, 'private'),
                  set(state`room.companionUid`, null),
                  sendCreateBroadcast
                ],
                false: []
              },
              set(state`app.isInPrivate`, null)
            ],
            isViewer: [
              ({ get, store }) => {
                const firstViewed = get(state`auth.firstViewed`)
                const roomEnterTime = get(state`room.roomEnterTime`)
                if (roomEnterTime === 0) {
                  return
                }

                //здесь всегда приват, значит не нужно ограничение на 10%

                const streamData = get(state`room.streamData`) || {
                  bitrate: 0,
                  height: 0,
                  width: 0,
                  protocol: 'none'
                }
                const metadata = getStreamMetadata(get(state`app.isInPrivate`))

                const amData = {
                  viewing_category: 'viewing_broadcast',
                  content_rating: getAgeRatingByUid(get(state`app.isInPrivate`)),
                  model_id: get(state`app.isInPrivate`),
                  subscription_type: '-1',
                  broadcast_type: 'private',
                  end_reason: 'model',
                  starting_duration: 0,
                  viewing_duration: 0,
                  topic_id: metadata.postThemeId || '-1',
                  avg_bitrate: streamData.bitrate / 1000000,
                  height: streamData.height,
                  width: streamData.width,
                  protocol: streamData.protocol || 'HLS',
                  firstView: !firstViewed
                }

                if (streamData.videoStartTime > 0) {
                  const brWatchTime = Date.now() - streamData.videoStartTime
                  if (brWatchTime < 100 || brWatchTime > 7575759 || !props.themeId) {
                    return
                  }
                  const starting_duration = streamData.videoStartTime - roomEnterTime
                  if (starting_duration < 10 || starting_duration > 1000000) {
                    return
                  }

                  amData.starting_duration = starting_duration / 1000
                  amData.viewing_duration = brWatchTime / 1000
                }

                store.set(state`auth.firstViewed`, true)
                if (amData.viewing_duration) {
                  sendAmplitudeEvent('topic_duration_user', amData)
                }
              },
              set(state`app.isInPrivate`, null)
            ],
            isOther: []
          }
        ],
        false: []
      },
      unsetPaidTranslation
    ],
    paidForWatching: [//зритель
      ({ store, get, props }) => {
        store.set(state`auth.coins`, props.params.purchasedCoins)
        store.set(state`auth.usdBalance`, Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100)
        const roomId = get(state`app.roomId`)
        const subscription_type = (needSubscription(roomId, LIVE_PERMISSION) || '-1').toLowerCase()
        const amdata = {
          subscription_type,
          type: props.params.type,
          sent_coins: props.params.send,
          age_rating: getUserAgeRating(roomId),
          content_rating: getAgeRatingByUid(roomId),
          counterparty_id: roomId || '-1',
          product_type: 'view',
        }

        if (get(state`app.isInPrivate`)) {
          amdata.topic_id = get(state`app.privateThemeId`)
          amdata.broadcast_type = 'private'
          sendAmplitudeEvent('topic_pay', amdata)
        } else {
          const metadata = getStreamMetadata(roomId) || {}
          amdata.topic_id = (metadata.postThemeId || '-1') + ''
          amdata.broadcast_type = 'public'
          sendAmplitudeEvent('topic_pay', amdata)
        }
      }
    ],
    paidForWatchingReceived: [//модель
      ({ store, get, props }) => {
        store.set(state`auth.coins`, props.params.purchasedCoins)
        store.set(state`auth.usdBalance`, Math.trunc((props.params.receivedCoins / get(state`intl.settings.coinsToMoney`)) * 100) / 100)
      }
    ],
    clearAllPaid: [],
    cantEnterInRoom: [
      ({ props, path }) => {
        if (props.params === 'inPrivate') return path.inPrivate()
        if (props.params === 'noCoinsForWatching') return path.stopWatch()
        if (props.params.requiredSubscriptionType) return path.stopWatch()

        return path.unknownReason()
      }, {
        stopWatch: set(state`room.allowedPaidStreamId`, null),
        unknownReason: [
          routeToFactory(postersRoute),
        ],
        inPrivate: [
          routeToFactory(postersRoute),
          showSnackbar(state`intl.content.private_show_already_in_show`, 5000, 'error')
        ]
      }
    ],
    paidLike: [
      when(props`params.noCoins`), {
        true: () => [
          ({ get }) => {
            const coins = +get(state`auth.coins`)
            const message = get(state`intl.content.need_more_coins`)
            return {
              message: message.replace(/\{0\}/gi, 3000 - coins),
              reason: 'like-click'
            }
          },
          openShop
        ],
        false: [
          when(props`params.to`, state`auth.uid`, (model, uid) => model === uid), {
            true: [
              ({ get, props: { params: { from, received } } }) => {
                let message = get(state`intl.content.user_send_present_to_user`)
                const name = get(state`auth.name`)
                const loadPromise = new Promise(resolve => loadUser(from, () => {
                  const user = get(state`app.users.${from}`)

                  message = message
                    .replace('%from%', user.name)
                    .replace('%to%', name)
                    .replace('%present%', Math.trunc((received / get(state`intl.settings.coinsToMoney`)) * 100) / 100)
                  resolve(message + '$')
                }))
                return loadPromise.then(message => ({ message }))
              },
              showSnackbar(props`message`, 5000, 'gift'),
            ],
            false: [
              ({ props, get }) => {
                if (props.params.from !== get(state`auth.uid`)) {
                  return
                }
                const subscription_type = (needSubscription(props.params.to, LIVE_PERMISSION) || '-1').toLowerCase()
                const amdata = {
                  subscription_type,
                  sent_coins: props.params.received,
                  age_rating: getUserAgeRating(props.params.to),
                  content_rating: getAgeRatingByUid(props.params.to),
                  counterparty_id: props.params.to,
                  product_type: 'donate',
                  activity_id: props.params.item
                }

                if (get(state`app.isInPrivate`)) {
                  amdata.topic_id = get(state`app.privateThemeId`)
                  amdata.broadcast_type = 'private'
                  sendAmplitudeEvent('topic_pay', amdata)
                } else {
                  const metadata = getStreamMetadata(get(state`app.roomId`)) || {}
                  amdata.topic_id = (metadata.postThemeId || '-1') + ''
                  amdata.broadcast_type = 'public'
                  sendAmplitudeEvent('topic_pay', amdata)
                }
              }
            ]
          },
          when(state`room.streamInRoom`, state`auth.uid`, (chatId, uid) => chatId === uid), {
            true: [
              ({ get, props: { params: { from, to, received } } }) => ({
                roomId: get(state`app.roomId`),
                msgData: {
                  from,
                  to,
                  postId: new Date().getTime(),
                  text: '+$' + Math.trunc((received / get(state`intl.settings.coinsToMoney`)) * 100) / 100,
                  themeMsg: true,
                }
              })
            ],
            false: [
              ({ get, props: { params: { from, to, received } } }) => ({
                roomId: get(state`app.roomId`),
                msgData: {
                  from,
                  to,
                  postId: new Date().getTime(),
                  text: '+' + COIN_ICON + received,
                  themeMsg: true,
                }
              })
            ]
          },
          onNewMessage,
        ]
      }
    ],
    removeMessage: [
      banMsg
    ],
    giftSent: [
      addGiftsMessage
    ],
    giftSetSent: [
      addGiftsMessage
    ],
    onGetGiftTop: [
      set(state`room.giftTops.${props`params.topId`}`, props`params`),
    ],
    reloadSettings: [
      //TODO: обновить наcтройки 
    ],
    subscribed: [],
    undefined: [],
    doNothing: [],
    newReview: [
      ({ props }) => addLocalReview(props.params)
    ],
    deleteReview: [
      ({ props }) => delLocalReview(props.params)
    ],
    onGetLastCaller: [
      when(state`app.online.${props`params.uid`}`), {
        true: [
          showSnackbar(state`intl.content.prvt_call`, 60000, 'callToModel', props`params.uid`)
        ],
        false: [
          ({ props }) => ({ uids: [props.params.uid] }),
          requestUsers, {
            success: [
              ({ props, get }) => ({
                text: get(state`intl.content.call_cancelled`).replace('%userName%', getUser(props.params.uid).name),
                type: 'error'
              }),
              showSnackbarSignal,
            ]
          }
        ]
      }
    ],
    onCommentMarkRead: [],
    onCallToPrivate: [],
    /**переключим на новую инормацию */
    streamsUpdate: [
      ({ props, get, store }) => updateStreams({ store, get, streams: props.params })
    ],
    streamsUpdateAnt: [
      ({ props, get, store }) => updateStreamsAnt({ store, get, streams: props.params })
    ],
    newPost: [
      ({ props, get, store }) => {
        addNewPostsCount(props.params.author)
      }
    ],
    quizQuestion: [
      ({ props, get, store }) => {
        askQuiz(props.params.quiz)
      }
    ],
    quizWinner: [
      ({ props, get, store }) => {
        quizWinner(props.params)
      }
    ],
    updateEnergy: [
      ({ props, get, store }) => {
        updateMyEnergy(props.params)
      }
    ],
    updateLastRead: [
      ({ props, get, store }) => {
        updateLastRead(props.params)
      }
    ],
    unknownMethod: [
      ({ props }) => {
        console.warn('unknown socket method:', props.data)
        //throw new Error('unknown socket method')
      }
    ]
  }
]

import { getState } from './StoreManager'
import { checkCountry } from './ageRating/ageRatingUtil'
import { isMSPWA, isTWA } from './twa'
import { getHost } from './mobileAppUrls'

export function isPaymentAvailable() {
  const paymentSystems = getState('intl.appSourceConfig.paymentSystems')
  if (!paymentSystems || paymentSystems.length < 1) {
    return false
  }

  return true
}

function checkPayerMax(paymentSystems) {
  if (getState('app.twa_payment_config')) {
    return
  }
  const host = getHost()

  if (!(host === 'ulive.chat' || host === 'flirtymania.com')) {
    return
  }

  if (checkCountry('ru')) {
    return
  }

  // if (
  //   checkCountry('ar') ||
  //   checkCountry('br') ||
  //   checkCountry('co') ||
  //   checkCountry('ag') ||
  //   checkCountry('eg') ||
  //   checkCountry('id') ||
  //   checkCountry('in') ||
  //   checkCountry('jo') ||
  //   checkCountry('ma') ||
  //   checkCountry('mx') ||
  //   checkCountry('my') ||
  //   checkCountry('px') ||
  //   checkCountry('sa') ||
  //   checkCountry('th') ||
  //   checkCountry('tr') ||
  //   checkCountry('vn') ||
  //   imSuperAdmin()
  // ){
  if (paymentSystems && paymentSystems.length == 1) {
    return true
  }
  // }
}

export function getPaymentsSystems() {
  let paymentSystems = getState('intl.appSourceConfig.paymentSystems')

  if (isMSPWA()) {
    if (checkCountry('ru')) {
      paymentSystems = ['op']
    } else {
      paymentSystems = ['cb']
    }
  } else if (checkCountry('ru') && !isTWA()) {
    paymentSystems = ['op']
  }
  if (checkPayerMax(paymentSystems)) {
    paymentSystems.push('pm')
  }
  return paymentSystems
}

// export function swapPayment(appSourceConfig){
//   if (checkCountry('ru') && !isTWA()){
//     appSourceConfig.paymentSystems = ['po']
//   }
// }

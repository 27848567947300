import { wait } from '@/utils'
import { state } from 'cerebral'
import { isTWA } from '@/utils/twa'
import isVip from '../../../utils/isVip'
import { DAILY_COINS, DAILY_COINS_NOVIP, NO_DAILY_COINS, ROOM_CHAT_BAN } from '../../buffs'
import { updateAgeRating } from '../../../utils/ageRating/ageRatingUtil'
import { getMyLSProp, loadMyLSProps } from '../../../utils/LocalStorageUtil'
import { startLoadOnlineSubs } from '../../../utils/onlineSubsUtil'
import { callSequence, setStoreWrapper } from '../../../utils/StoreManager'
import { getMyUid, imVIP } from '../../../utils/my'
import { addMeInTopUids } from '../../../utils/topBar/topBarFillUtil'
import { isBuffActive } from '../../../utils/buffUtils'
import { createCallPanel } from '../../../components/Panels/createPanelUtil'
import { CHAT_DRAFT } from '../../../utils/inputUtil'
import { loadMyData } from '../../../utils/my/loadMyData'
import { HIDDEN_COUNTRY } from '../../../utils/geo/getLocation'
import { getAwardsProgress } from '../../../utils/awards/awardsProgressUtil'
import { addFansOnAuth } from '../../fans/fansUtil'

export default async ({ store, get, params }) => {
  for (let i = 0; i < 5; i++) {
    try {
      return saveUserData({ store, get, params })
    } catch (e) {
      console.log('@@@ auth error:', e)
      await wait(1000)
    }
  }
}

const needUpdateTWA = ({ get }) => {
  if (!isTWA()) return false
  const settings = get(state`intl.settings`)
  const v = get(state`app.twa_version`) || ''
  const buildNum = v.split('.').pop()
  const appid = get(state`app.appId`)
  if (buildNum && settings && settings[appid] && settings[appid] > parseInt(buildNum)) {
    return true
  }
  return false
}

const saveUserData = ({ store, get, params }) => {
  const { authMode, email, password_set, uid, model, receivedCoins, purchasedCoins, multi_buffs } = params

  getAwardsProgress(uid)
  callSequence('intl.loadCountries')
  const usdExchangeRate = get(state`intl.settings.coinsToMoney`)
  const usdBalance = receivedCoins / usdExchangeRate

  if (params.birthday) {
    setStoreWrapper('myBDay', params.birthday * 1000)
  }

  store.set('auth.lang', params.lang)
  console.log('AUTH_LANG ' + params.lang)
  if (needUpdateTWA({ get, store })) {
    store.set('app.showUserRedirect', true)
  }

  for (const key in params) {
    if (key === 'fans') {
      addFansOnAuth(params[key])
    }
    if (key === 'fans' || key === 'subs' || key === 'friends' || key === 'coins') {
      continue
    }
    store.set(`auth.${key}`, params[key])
  }

  if (!multi_buffs[NO_DAILY_COINS]) {
    //подарок только у VIP
    if (imVIP()) {
      multi_buffs[isVip() ? DAILY_COINS : DAILY_COINS_NOVIP] = [{
        start: Date.now() / 1000,
        buff_id: isVip() ? DAILY_COINS : DAILY_COINS_NOVIP
      }]
    }
    store.set('auth.multi_buffs', multi_buffs)
  }

  addMeInTopUids()

  store.set('auth.newFollowersCount', params.newFollowersCount || 0)

  //TODO настоящий параметр счетчика новых матчей
  store.set('auth.newMatchCount', params.newMatchCount || 0)

  loadMyData(uid, params, get)

  store.set('auth.geoIpInfo', params.geoIpInfo)

  // store.set('auth.isAuthComplete', true)
  store.set('auth.isLoggedIn', true)
  store.set('auth.hiddenLocation', (params.storage && params.storage.hidden_location))
  if (params.storage && params.storage.hidden_location) {
    store.set('selectedCountry', HIDDEN_COUNTRY)
  } else {
    store.set('selectedCountry', null)
  }
  store.set('auth.isTempUser', authMode === 'gu')
  store.set('auth.isPasswordSet', password_set)
  store.set('auth.email', email)
  store.set('auth.paidContacts', params.paid_contacts)
  store.set('auth.donateLink', (params.storage && params.storage.donate_url))
  store.set('auth.freeSendMedia', (params.storage && params.storage.free_incoming_media))

  const chatDraft = getMyLSProp(CHAT_DRAFT)
  if (chatDraft) {
    store.set(CHAT_DRAFT, chatDraft)
  }

  if (model) store.set('auth.model', model)
  if (purchasedCoins) store.set('auth.coins', purchasedCoins)
  if (receivedCoins) store.set('auth.receivedCoins', receivedCoins)
  //if (purchasedCoins) store.set('auth.purchasedCoins', purchasedCoins)
  if (usdBalance) store.set('auth.usdBalance', Math.trunc(usdBalance * 100) / 100)

  if (isVip(params.uid) && get(state`app.route`) === 'room') {
    store.set('room.allowedPaidStreamId', get(state`app.roomId`))
  }

  startLoadOnlineSubs()
  //смена возрастного рейтинга при авторизации
  updateAgeRating({ store, get })
  const filters = get(state`posts.filters`)
  const filtersClear = filters.filter(item => {
    return Object.keys(item).indexOf('interests') < 0
  })
  store.set('auth.interestsTags', [])
  store.set('posts.filters', filtersClear)
  store.set('auth.myLSProps', loadMyLSProps(uid))
  const fwdProps = { uid: uid }

  //проверим, есть ли мне входяший вызов
  if (params.privateCall) {
    if (isBuffActive(params.privateCall.fromUid, ROOM_CHAT_BAN, getMyUid())) {
      return
    }
    createCallPanel(params.privateCall.fromUid, true, params.privateCall.themeId)
  }

  // setTimeout(()=>{ nyGiftDiscountActivate() }, 5000)
  return fwdProps
}

import { getParentDomain, isPartnerDomain } from '@/utils/urlUtils'
import { state } from 'cerebral'
import { imAdmin, imSuperAdmin } from '../../utils/adminUtil'
import { getAgeRatingByUid } from '../../modules/posters/showInRoom'
import controller from '@/controller'
import { isTWA } from '../twa'
import { getState } from '../StoreManager'
import { isUserBannedMyCountry } from '../userLoader'
import { isMe } from '../checks'
import { isBuffActive } from '../buffUtils'
import { GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18 } from '../../modules/buffs'
import { getPostAuthor, getPostData } from '../postUtils'
import { checkMySubscription, isVIPPost } from '../subscriptionUtil'
import { checkOnline, isStreaming } from '../../modules/socket/actions'
import { imVIP } from '../my'
import { getProfilePostData, getProfilePostId } from '../userUtil'
import { is12Config } from '../appConfigUtil'

let liveAgeRatings = [12]
let liveAgeRatingsNSFW = []

let postAgeRatings = [12]
let postAgeRatingsNSFW = []
export let allPostRatings = [12]

let blogPostAgeRatings = [12]
let blogPostAgeRatingsNSFW = []
export let allBlogRatings = [12]

export let publicTagsAgeRating = [12]
let subsAgeRatings = [12]
let subsAgeRatingsNSFW = []
export let allSubsRatings = [12]

//обновление возрастного рейтинга при коннекте к серверу
export const updateAgeRating = ({ store, get }) => {
  const appSourceConfig = is12Config() ? {} : get(state`intl.appSourceConfig`) || {}

  console.log('country ' + getState('intl.country'))
  //если это наш домен и я не супер админ, и запуск из RU то конфиг 12+ без NSFW
  // if ((checkCountry('ru') ||
  //     checkCountry('sg')
  //   // checkCountry('hk') ||
  //   // checkCountry('id') ||
  //   // checkCountry('cn') ||
  //   // checkCountry('in') ||
  //   // checkCountry('tw')
  //   ) &&
  //   !imSuperAdmin()){
  //   return
  // }

  let ageRating = getBaseAgeRating(appSourceConfig)

  /** не понятно, что за ворнинг, вроде больше не нужен

   const params = new URLSearchParams(window.location.search)
   if (ageRating > 17 && !get(state`app.warningAccepted`) && !params.get('skipWarning')) {
   store.set('app.showWarning', true)
   }
   */

  console.log('isPartner ' + isPartnerDomain() + ' imSuper ' + imSuperAdmin() + ' imAdmin ' + imAdmin())

  //форсированное ограничение по стране отключает NSFW даже если он есть в конфиге
  let noNSFW = noNSWF()

  // если конфиг без NSFW все исходя из возрастного рейтинга и без NSFW
  if (noNSFW) {
    liveAgeRatings = postAgeRatings = blogPostAgeRatings = subsAgeRatings =
      allPostRatings = allBlogRatings = allSubsRatings = genAgeRatingArray(ageRating)
    liveAgeRatingsNSFW = postAgeRatingsNSFW = subsAgeRatingsNSFW = blogPostAgeRatingsNSFW = []

    //если обычный конфиг
  } else {
    //хак, с копией, что бы не портить исходный массив в appSourceConfig
    if (appSourceConfig.liveRatings) {
      liveAgeRatings = [...appSourceConfig.liveRatings.slice()]
    } else {
      liveAgeRatings = genAgeRatingArray(ageRating)
    }

    //пока нет деления по рейтингам для подписок и обычных постов используем параметр для постов
    if (appSourceConfig.postRatings) {
      blogPostAgeRatings = [...appSourceConfig.postRatings.slice()]
      subsAgeRatings = [...appSourceConfig.postRatings.slice()]
      postAgeRatings = [...appSourceConfig.postRatings.slice()]
    } else {
      blogPostAgeRatings = genAgeRatingArray(ageRating)
      subsAgeRatings = genAgeRatingArray(ageRating)
      postAgeRatings = genAgeRatingArray(ageRating)
    }

    //запишем NSFW настройки
    if (appSourceConfig.liveRatingsNSFW) {
      liveAgeRatingsNSFW = [...appSourceConfig.liveRatingsNSFW.slice()]
    } else {
      liveAgeRatingsNSFW = []
    }
    if (appSourceConfig.postRatingsNSFW) {
      postAgeRatingsNSFW = [...appSourceConfig.postRatingsNSFW.slice()]
    } else {
      postAgeRatingsNSFW = []
    }
    if (appSourceConfig.subsRatingNSFW) {
      subsAgeRatingsNSFW = [...appSourceConfig.subsRatingNSFW.slice()]
    } else {
      subsAgeRatingsNSFW = []
    }
    if (appSourceConfig.blogPostRatingsNSFW) {
      blogPostAgeRatingsNSFW = [...appSourceConfig.blogPostRatingsNSFW.slice()]
    } else {
      blogPostAgeRatingsNSFW = []
    }

    //сгенерируем финальные массивы возрастных рейтингов
    allPostRatings = noDouble([...postAgeRatings, ...postAgeRatingsNSFW])
    allSubsRatings = noDouble([...postAgeRatings, ...postAgeRatingsNSFW, ...subsAgeRatingsNSFW])
    allBlogRatings = noDouble([...blogPostAgeRatings, ...blogPostAgeRatingsNSFW])
  }
  console.log('BEFORE liveAgeRatingsNSFW ' + liveAgeRatingsNSFW.toString())

  //если это наш домен, а не партнера или TWA и я не супер админ, то RU IP то уберем все виды 18+ контента
  // if (
  //   !isTWA() &&
  //   ((checkCountry('ru') || (checkCountry('nl'))) && !isPartnerDomain()) &&
  //   !imSuperAdmin()
  // ){

  /** Всегда убираем 18+ */
  ageRating = Math.min(16, ageRating)
  remove18(allPostRatings)
  remove18(allSubsRatings)
  remove18(allBlogRatings)

  remove18(liveAgeRatingsNSFW)
  remove18(postAgeRatingsNSFW)
  remove18(subsAgeRatingsNSFW)
  remove18(blogPostAgeRatingsNSFW)

  remove18(subsAgeRatings)
  remove18(postAgeRatings)

  remove18(liveAgeRatings)
  remove18(blogPostAgeRatings)

  console.log('country ageRating', ageRating)
  // }

  //сгенерим возрастной рейтинг тегов
  publicTagsAgeRating = genPublicTagsAgeRating(ageRating)
  store.set('app.ageRating', ageRating)
  console.log('live AR ' + liveAgeRatings.toString())
  console.log('final AgeRating ' + ageRating.toString())
  console.log('final liveAgeRatingsNSFW ' + liveAgeRatingsNSFW.toString())
}

function noDouble(ageRatings) {
  return ageRatings.filter((item, pos) => ageRatings.indexOf(item) === pos)
}

function remove18(ageRatings) {
  const i = ageRatings.indexOf(18)
  if (i !== -1) {
    ageRatings.splice(i, 1)
    console.log('removed 18 ' + ageRatings)
  }
}

function genAgeRatingArray(ageRating, no18 = null) {
  if (!ageRating || ageRating === 12) {
    return [12]
  }

  if (ageRating === 14) {
    return [12, 14]
  }

  if (ageRating === 16) {
    return [12, 14, 16]
  }

  if (ageRating === 18 && no18) {
    return [12, 14, 16]
  } else {
    return [12, 14, 16, 18]
  }
}

//определение базового возрастного рейтинга по параметрам в URL
const getBaseAgeRating = (config) => {
  console.log('detect age rating')

  if (is12Config()) {
    console.log('r config')
    return 12
  }

  const hardAgeRating = controller.getState('app.hardAgeRating')
  if (hardAgeRating > 0) {
    console.log('partner rating set', hardAgeRating)
    return hardAgeRating
  }

  return config.ageRating || 14
}

export const isStreamNSFW = uid => {
  return liveAgeRatingsNSFW.indexOf(getAgeRatingByUid(uid)) !== -1
}

export const isStreamNormal = uid => {
  return liveAgeRatings.indexOf(getAgeRatingByUid(uid)) !== -1
}

// export const isPostVisible = ageRating => {
//   if (!ageRating){
//     return false
//   }
//   return postAgeRatings.indexOf(ageRating) !== -1
// }

function checkPostByAgeRating(ageRating) {
  if (!ageRating) {
    return false
  }
  return postAgeRatings.indexOf(ageRating) !== -1
}

export function isPostVisible(postId) {
  const pid = getPostData(postId)
  if (!pid || !pid.ageRating) {
    return false
  }
  if (isUserBannedMyCountry(getPostAuthor(postId))) {
    return false
  }
  return postAgeRatings.indexOf(pid.ageRating) !== -1
}

export const isTagAgeGood = ageRating => {
  if (!ageRating) {
    return false
  }
  return publicTagsAgeRating.indexOf(ageRating) !== -1
}

export const isAllowedNSFWVisible = ageRating => {
  if (!ageRating) {
    return false
  }
  return allPostRatings.indexOf(ageRating) !== -1
}

const nsfwVisiblyCountry = ['us', 'de', 'uk', 'dk', 'it', 'es', 'be', 'kw', 'fr', 'in', 'is', 'gr', 'cz']
export const posterVisibleStream = uid => {
  //если я девушка, то вижу стримы не выше 12
  // if (imFemale()){
  //   return getAgeRatingByUid(uid) === 12
  // }
  if (isUserBannedMyCountry(uid)) {
    return false
  }

  if (getParentDomain().includes('ulive.chat') || getState('app.subject') === 'ulive.chat') {
    return isStreamNormal(uid)
  }
  const country = getState('intl.country')
  if (imVIP() && country && nsfwVisiblyCountry.includes(country)) {
    return isStreamNormal(uid) || isStreamNSFW(uid)
  }

  return isStreamNormal(uid)
}

export const roomVisibleStreamWithNSFW = uid => {
  const country = getState('intl.country')
  if (imVIP() && country && nsfwVisiblyCountry.includes(country)) {
    return isStreamNormal(uid) || isStreamNSFW(uid)
  }

  return isStreamNormal(uid)
  // return isStreamNormal(uid) || isStreamNSFW(uid)
}

export const posterVisibleStreamByAgeRating = ageRating => {
  return liveAgeRatings.indexOf(ageRating) !== -1 || liveAgeRatingsNSFW.indexOf(ageRating) !== -1
}

export const isPostNSFW = postId => {
  const postData = controller.getState(`posts.postsData.${postId}`)
  if (!postData) {
    return false
  }

  const { uid, ageRating, statusFromDb } = postData

  const myUid = controller.getState('auth.uid')
  if (uid === myUid) return false

  if (statusFromDb === 0) {
    //если не ТВА и вижон считает что норм контент, то не NSFW
    if (!isTWA() && postData.isNotAdult) {
      return false
    }
    return true
  }

  if (ageRating && blogPostAgeRatings.indexOf(ageRating) !== -1) {
    return false
  }

  if (ageRating && blogPostAgeRatingsNSFW.indexOf(ageRating) !== -1) {
    return true
  }

  return true
}

export function isPostBlurForPoster(postId) {
  const uid = getPostAuthor(postId)
  if (isMe(uid)) {
    return false
  }

  const nsfw = isPostNSFW(postId)
  if (nsfw && !getState('auth.stuff.allowNsfw')) {
    return true
  }

  if (isTWA() && nsfw) {
    return true
  }

  if (isVIPPost(postId) && !imVIP()) {
    return true
  }

  return false
}

export const isOnlyApprovedPosts = () => {
  return isTWA() || isPayoutLink()
}

export const isPayoutLink = () => {
  return controller.getState('app.mobile')
}

export const noNSWF = () => {
  return isPayoutLink() || controller.getState('app.noNSFW')
}

export function isWaitForCall(uid) {
  return false //isWaitForCallByAR(getAgeRatingByUid(uid))
}

const isWaitForCallByAR = brAgeRating => {
  return false
  if (posterVisibleStreamByAgeRating(brAgeRating)) {
    return false
  }

  const waitForCallRating = controller.getState('intl.appSourceConfig.waitForCall') || 0
  if (brAgeRating <= waitForCallRating) {
    return true
  }
  return false
}

//возвращает список возрастых рейтингов тегов
export const genPublicTagsAgeRating = (ageRating) => {
  if (imAdmin()) {
    //для админов все
    return genAgeRatingArray(18)
  }
  //для остальных сгенерим на основе возрастного рейтинга размещения
  return genAgeRatingArray(ageRating)
}

export function checkCountry(country) {
  return country === getState('intl.country')
}

export function isProfileVisible(uid) {
  const profileId = getProfilePostId(uid)
  if (!profileId) {
    return false
  }
  if (isMe(uid)) {
    return profileId
  }
  const data = getProfilePostData(uid)
  if (data.isNotAdult && !isTWA()) {
    return data.postId
  }

  if (checkPostByAgeRating(data.ageRating)) {
    return data.postId
  }
  return false
}

export function getUserAgeRating(uid) {
  if (isBuffActive(uid, GOOD_FOR_12)) {
    return 12
  }
  if (isBuffActive(uid, GOOD_FOR_14)) {
    return 14
  }
  if (isBuffActive(uid, GOOD_FOR_16)) {
    return 16
  }
  if (isBuffActive(uid, GOOD_FOR_18)) {
    return 18
  }

  return 0
}

export function getUserPostsCount() {

}

export function getMainAgeRating() {
  return getState('app.ageRating') || 12
}

export function getUserStatusString(uid) {
  let userStatus
  if (checkMySubscription(uid)) {
    if (isStreamNormal(uid) || isStreamNSFW(uid)) {
      userStatus = 'live'
    } else if (checkOnline(uid)) {
      userStatus = 'online'
    }
  } else {
    if (isStreaming(uid)) {
      userStatus = 'live'
    } else if (checkOnline(uid)) {
      userStatus = 'online'
    }
  }
  return userStatus
}

export function getPostAgeRating(postId) {
  const postData = getPostData(postId)
  if (!postData) {
    return -1
  }

  return postData.ageRating
}

import { state } from 'cerebral'
import {
  AFK,
  GOOD_FOR_12,
  GOOD_FOR_14,
  GOOD_FOR_16,
  GOOD_FOR_18,
  MAN_ON_CAM,
  WAIT_FOR_MODERATION,
  WRONG_FORMAT
} from '@/modules/buffs'
import { sendAmplitudeEvent } from '@/utils/GAEvent'
import { getAgeRatingByUid } from '../../posters/showInRoom'
import {
  AFTER_PRIVATE_DELAY,
  DAILY_COINS,
  DAILY_COINS_NOVIP,
  FREE_RATING,
  GIFT_DISCOUNT,
  NO_DAILY_COINS,
  NO_LIKE_AVAILABLE,
  PROMOTION,
  QUIZ,
  QUIZ_COOL_DOWN,
  ROOM_CHAT_BAN
} from '..'
import controller from '@/controller'
import { buffPanel } from '../../../components/Panels/types'
import { isMe } from '../../../utils'
import { createAddRatingPanel } from '../../../components/Panels/createPanelUtil'
import { checkCurrentRoomId } from '../../../utils/navigation/navigationUtil'
import { loadQuizInfo } from '../../../utils/quiz/quizAPI'
import { addBuffSnackbar } from '../../../utils/buffUtils'
import { isInMyRoom } from '../../../utils/user/userInfos'
import { buffActionMessage } from '../../auth/actions/notificationUtil'
import { imAdmin } from '../../../utils/adminUtil'
import { GIFTS_DISCOUNT_CARD } from '../../../utils/cardGame/cardGameUtil'
import { isCustomVanilla } from '../../../utils/api/PWAUtil'
import { loadUsers } from '../../../utils/userLoader'

/*buff: 
  "toUid":"03750d1fc91a31af0281500cbd7b2abf",
  "fromUid":"7c9b13df9dbbca07abac1eab32a996d6",
  "cardType":"4",
  "startTime":1523612850281,*/

export const ADD_BUFF_ACTION = 'add_buff'

export const addBuff = ({ props, store, get }) => {
  const { toUid, fromUid } = props.buff
  const buffId = props.buff.cardType + ''
  const startTime = props.buff.startTime > 1000000000000 ? props.buff.startTime / 1000 : props.buff.startTime

  const cardInfo = get(state`buffs.cards.${buffId}`)
  if (!cardInfo) {
    return
  }
  //если это баф викторины и я в комнате, то запросим информацию
  if (buffId === QUIZ && checkCurrentRoomId(toUid)) {
    loadQuizInfo()
  }

  if (isMe(toUid) && buffId === GIFT_DISCOUNT) {
    sendAmplitudeEvent('gift_discount', { status: 'add' })
    addBuffSnackbar(buffId, GIFTS_DISCOUNT_CARD)
  }

  if (cardInfo.invisible && !imAdmin()) {
    return
  }

  //если я его накладываю или тот кто его получает в одной комнате со мной - покажем сообщение
  if (isMe(toUid) || isMe(fromUid) || isInMyRoom(toUid) || isInMyRoom(fromUid)) {
    if (!isCustomVanilla() &&
      buffId !== GOOD_FOR_12 &&
      buffId !== GOOD_FOR_14 &&
      buffId !== GOOD_FOR_16 &&
      buffId !== GOOD_FOR_18 &&
      buffId !== QUIZ_COOL_DOWN &&
      buffId !== QUIZ &&
      buffId !== NO_LIKE_AVAILABLE &&
      buffId !== FREE_RATING &&
      buffId !== ROOM_CHAT_BAN &&
      buffId !== NO_DAILY_COINS &&
      buffId !== DAILY_COINS &&
      buffId !== AFTER_PRIVATE_DELAY &&
      buffId !== WAIT_FOR_MODERATION
    ) {
      loadUsers([toUid, fromUid], () => {
        buffActionMessage(buffId, fromUid, toUid, ADD_BUFF_ACTION)
      })
    }
  }

  const user = get(state`app.users.${toUid}`)
  if (!user) {
    return
  }

  if (!user.multi_buffs) {
    user.multi_buffs = {}
  }
  if (!user.multi_buffs[buffId]) {
    user.multi_buffs[buffId] = []
  }
  user.multi_buffs[buffId] = user.multi_buffs[buffId].filter(
    b => (b.buff_id + '') !== (buffId + '') || b.from !== fromUid
  )
  const buffObj = {
    buff_id: buffId,
    start: startTime
  }
  if (fromUid) {
    buffObj.from = fromUid
  }
  user.multi_buffs[buffId].push(buffObj)

  store.set(`app.users.${toUid}`, user)

  if (isMe(toUid) && buffId === FREE_RATING) {
    createAddRatingPanel(fromUid)
  }
  const myUid = get(state`auth.uid`)

  if (toUid !== myUid) {
    return
  }

  if (buffId === PROMOTION) {
    sendAmplitudeEvent('promotion_buff')
  }

  if (buffId === GOOD_FOR_12 ||
    buffId === GOOD_FOR_14 ||
    buffId === GOOD_FOR_16 ||
    buffId === GOOD_FOR_18 ||
    buffId === WRONG_FORMAT ||
    buffId === AFK ||
    buffId === MAN_ON_CAM) {
    store.set('auth.lastModerRating', getAgeRatingByUid(get(state`auth.uid`)))
    if (user.multi_buffs[WAIT_FOR_MODERATION]) {
      delete user.multi_buffs[WAIT_FOR_MODERATION]
      store.set(`app.users.${toUid}`, user)

      let ageRating
      if (buffId === GOOD_FOR_12) {
        ageRating = 12
      } else if (buffId === GOOD_FOR_14) {
        ageRating = 14
      } else if (buffId === GOOD_FOR_16) {
        ageRating = 16
      } else if (buffId === GOOD_FOR_18) {
        ageRating = 18
      } else {
        ageRating = buffId
      }

      checkModerationTime({ get, ageRating })
    }
  }

  if (buffId === NO_DAILY_COINS) {
    delete user.multi_buffs[DAILY_COINS]
    delete user.multi_buffs[DAILY_COINS_NOVIP]
    store.set(`app.users.${toUid}`, user)
  }

  if (cardInfo.blocksBroadcasting) {
    store.set('room.streamStopped', true)
    setTimeout(() => store.set('room.streamStopped', false), 250)
    return
  }

  const postThemeId = get(state`auth.storage.metadata.postThemeId`)
  let postTheme = null
  if (postThemeId) {
    postTheme = get(state`posts.postsData.${postThemeId}`)
  }

  if (([GOOD_FOR_12, GOOD_FOR_14, GOOD_FOR_16, GOOD_FOR_18, PROMOTION].includes(buffId) && !postTheme) ||
    (buffId === GOOD_FOR_18 || buffId === GOOD_FOR_16)
  ) {
    const createPanel = controller.getSequence('app.createPanel')
    createPanel(buffPanel(buffId, toUid, fromUid))
  }

}

const checkModerationTime = ({ get, ageRating }) => {
  const datetime_of_creation = get(state`room.broadcastStartTime`)
  const datetime_of_moderation = Date.now()
  const moderation_time = Math.round((datetime_of_moderation - datetime_of_creation) / 1000)

  sendAmplitudeEvent('topic_create', {
    progress: 'moderation',
    codec: get(state`room.codec`) || 'H264',
    moderation_time: moderation_time,
    datetime_of_creation,
    datetime_of_moderation,
    content_rating: ageRating
  })
}

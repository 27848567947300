import { state } from 'cerebral'
import { loadUsers } from '@/utils/userLoader'
import { addNewMessage, overlayChatScrollToEnd } from '../../../utils/chat/chatMessagesManager'
import { isMe } from '../../../utils'
import { getState } from '../../../utils/StoreManager'

export default ({ props, store, get }) => {
  if (!props.roomId || !props.msgData) {
    return
  }

  const msg = props.msgData

  loadUsers([msg.from, msg.to], users => {
    addNewMessage()
    if (!msg.postId) {
      msg.postId = new Date().getTime().toString(36)
    }
    if (msg.from && msg.from !== 'fm') {
      msg.fromName = users[msg.from].name
      msg.fromNameTsc = users[msg.from].total_subscription_cost
    }
    if (msg.to) {
      msg.toName = users[msg.to].name
      msg.toNameTsc = users[msg.to].total_subscription_cost
    }

    const messages = get(state`chat.roomsMessages.${props.roomId}`) || []
    messages.push(msg)

    const len = messages.length
    // TODO: в cerebral/operators еcть push, который заботитcя обо вcех изменениях
    //эта магия c % 2 нужна, чтобы изменялоcь кол-во cообщений 
    //в маccиве при каждом новом cообщении
    while (messages.length > 24 && len % 2 === 0) {
      messages.shift()
    }

    store.set(`chat.roomsMessages.${props.roomId}`, messages)
    //если чат отскролен, и нет открытого поля ввода то не скролим
    //так же не скролим, если сообщение не от меня
    const overlayScrolled = getState('overlayScrolled')
    if (isMe(msg.from)) {
      overlayChatScrollToEnd()
    } else if (!overlayScrolled) {
      overlayChatScrollToEnd()
    }

    const roomSpectators = get(state`app.roomSpectators.${props.roomId}`) || [props.roomId]
    if (roomSpectators.indexOf(msg.from) === -1) {
      roomSpectators.push(msg.from)
      store.set(`app.roomSpectators.${props.roomId}`, roomSpectators)
    }
  })
}

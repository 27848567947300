import controller from '@/controller'
import { inIframe } from './inIframe'
import { getPostData } from './postUtils'
import config from '@/config'
import { getPostPhotoUrl } from './settingsUtil'
import { setStoreWrapper } from './StoreManager'
import { isCDNDisable } from './appUtil'
import { getGiftImg } from './giftUtil'

const { imageUrl, noCDNImageUrl } = config

export function getImageUrl() {
  return isCDNDisable() ? noCDNImageUrl : imageUrl
}

export const getParentDomain = () => {
  let host = null
  if (inIframe()) {
    host = document.referrer ? new URL(document.referrer).host : document.location.host
    //host = location.host
  } else {
    host = document.location.host
  }

  return host
}

export const onPromoClick = (promo, lang, utm_medium) => {
  let host = location.host
  const parentDomain = getParentDomain()
  if (!inIframe() && (parentDomain.startsWith('localhost') || parentDomain.includes('.js.faminta.com'))) {
    host = 'flirtymania.com'
  }

  let url = `https://${promo.domain}/${lang}/p?utm_source=${location.host}&utm_medium=${utm_medium}&utm_campaign=cross_promo&utm_term=${location.pathname}`
  url = `https://${host}/redirect?to=${encodeURIComponent(url)}`
  window.open(url, '_blank').focus()
}

export const isPartnerDomain = () => {
  if (document.referrer) {
    const refHost = new URL(document.referrer).host
    return inIframe() && refHost !== location.host
  }
  return inIframe()
}

export const isLocalHost = () => {
  let local = false
  if (window.location.host) {
    // const refHost = new URL(document.referrer).host
    local = window.location.host.indexOf('localhost') != -1
  }

  return local
}

export function getSupportLink(appId, settings) {
  const lang = controller.getState('intl.lang')

  if (appId && settings) {
    const urlHelp = settings['support_' + appId]
    return urlHelp
  }
  return `http://help.${location.host}/${lang}/`
}

export function payoutAllowed() {
  return !controller.getState('intl.appSourceConfig.payoutsDisabled')
}

export function validProtocol(url) {
  if (url && url.indexOf('http') !== 0) {
    return 'https://' + url
  }

  return url
}

const VIDEO_POST = 1

function getPostPreview(postId, size) {
  const data = getPostData(postId)
  if (!data || !data.photoSizes) {
    return null
  }

  if (data.postType === VIDEO_POST) {
    return data.photoUrl
  }
  let bestSize = 0
  //игнорим DPI выше 3
  size = size * Math.min((window.devicePixelRatio || 1), 3)
  Object.keys(data.photoSizes).forEach(key => {
    const currentSize = parseInt(key)
    if (!bestSize) {
      bestSize = currentSize
      return
    }
    let v1
    let v2
    if (bestSize < currentSize) {
      v1 = Math.abs(size - bestSize)
      v2 = Math.abs(size - currentSize)
    } else {
      v1 = Math.abs(size - bestSize)
      v2 = Math.abs(size - currentSize)
    }
    if (v1 > v2) {
      bestSize = currentSize
    }
  })
  if (bestSize === 0) {
    if (size <= SIZE_600 || data.middle) {
      return data.middle
    }

    if (data.original) {
      return data.original
    }

    if (data.photoUrl) {
      return data.photoUrl
    }
  }

  return getPostPhotoUrl(data.photoSizes[bestSize])
}

const extraLargePicDate = new Date(1663585188000)
const SIZE_300 = 300
const SIZE_600 = 600
const SIZE_1350 = 1350

export function getPostPreviewUrlBase(postId) {
  return getPostPreview(postId, SIZE_300)
}

export function getPostPreviewUrlLarge(postId) {
  return getPostPreview(postId, SIZE_600)
}

export function getPostPreviewUrClose(postId, size) {
  return getPostPreview(postId, size || SIZE_300)
}

export function getPostPicUrClose(postId, size) {
  return getPostPreview(postId, size || SIZE_1350)
}

export function getGiftUrl(giftId) {
  return getGiftImg(giftId)
}

export function getRatingUrl(rating) {
  if (rating > 5) {
    return getImageUrl() + `/stars_1/status_star_${rating}.webp`
  }
  return getImageUrl() + `/rating/star-${rating}@2x.webp`
}

export function getBigStarUrl(num) {
  return getImageUrl() + `/stars_1/bigstars_${num}.webp`
}

export function getStarUrl(num) {
  return getImageUrl() + `/stars_1/stars_${num}.webp`
}

export function getBaseImageUrl(url) {
  return getImageUrl() + url
}

export function cdnWSSToggle() {
  setStoreWrapper('wssCDN', !isWSSCDN())
}

export function isWSSCDN() {
  return false
  // return Boolean(getState('wssCDN'))
}

export function getAwardAvatarUrl(id) {
  return getImageUrl() + `/award/avatars/${id}.webp`
}

export function getAwardCardUrl(id) {
  // if (id === GIFTS_DISCOUNT_CARD){
  //   return getImageUrl() + `/award/card/gifts_discount_ny.webp`
  // }
  return getImageUrl() + `/award/card/${id}.webp`
}

export function getAwardBigCardUrl(id) {
  return getImageUrl() + `/award/big_card/${id}.webp`
}

export function getAwardCharacterUrl(id) {
  return getImageUrl() + `/award/${id}.webp`
}

export function getAwardLocationUrl(id) {
  return getImageUrl() + `/award/locations/${id}.webp`
}

export function getMagicUrl(id) {
  // if (id === GIFTS_DISCOUNT_CARD){
  //   return getImageUrl() + `/award/card_game/magic/gifts_discount_ny.webp`
  // }
  return getImageUrl() + `/award/card_game/magic/${id}.webp`
}

export function getBossUrl(id) {
  return getImageUrl() + `/award/card_game/boss/${id}.webp`
}

export function getBuffURL(buffId) {
  // if (buffId === GIFT_DISCOUNT){
  //   return getImageUrl() + '/cards/l/gifts_discount_ny.png'
  // }
  return getImageUrl() + '/cards/l/' + buffId + '.png'
}
